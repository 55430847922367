<template>
  <section
    class="subscription_sec"
    :style="{
      'background-image': 'url(' + subscription_image + ')',
      'background-repeat': 'no-repeat',
      'background-size': 'cover',
    }"
  >
    <div class="container">
      <div class="text d-flex align-items-center">
        <img src="../../assets/media/image/meassage.png" />
        <div class="mx-4">
          <p>{{ $t("Know everything new and new") }}</p>
          <h5>{{ $t("Subscribe now to see the latest offers") }}</h5>
        </div>
      </div>
      <form @submit.prevent="subscripe()">
        <input
          type="phone"
          name="phone"
          v-model="phone"
          :placeholder="$t('enter_your_phone')"
        />
        <button class="btn PrimaryButton">{{ $t("Subscription") }}</button>
      </form>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { organizationSettings } from "@/store/modules/organizationSettings";
import { mapState } from "pinia";

export default {
  name: "SubscriptionSec",
  data() {
    return {
      subscription_image: null,
      message: "",
      phone: "",
    };
  },
  computed: {
    ...mapState(organizationSettings, ["whatsapp"]),
  },
  methods: {
    webSiteHeaders() {
      axios
        .get(`/fetch_web_site_headers`)
        .then(
          (res) => (this.subscription_image = res.data.data.subscription_image)
        )
        .catch((err) => console.log(err));
      // console.log(searchText);
    },

    subscripe() {
      axios
        .post("/store_news_letter_phone", { phone: this.phone })
        .then(({ data }) => {
          this.message = data.data;
          Swal.fire({
            position: "center",
            icon: "success",
            title: data.message,
            timer: 3000,
          });
          window.open(`${this.whatsapp}`, "_blank");
          this.phone = "";
        })
        .catch((error) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: error?.response?.data?.message,
            timer: 3000,
          });
          return error;
        })
        .finally(() => {});
    },
  },
  created() {
    this.webSiteHeaders();
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
@import "./_Subscription.scss";
</style> -->
