<template>
  <div class="col-lg-3 col-md-12 col-12 py-3">
    <div class="d-flex">
      <router-link
        :to="{ name: 'pills' }"
        class="btn SecondButton width-fit-content fs-12 d-flex align-items-center justify-content-center"
        title="print_pill"
      >
        <i class="fa-solid fa-print"></i>
      </router-link>
    </div>
    <div class="card-detalis">
      <div class="header">
        <h4 class="fw-bold">
          {{ $t("reservation_detials") }}
        </h4>
      </div>
      <div class="d-flex align-items-center justify-content-between gap-2">
        <div>
          <div class="dit">
            <h5 class="d-flex flex-column gap-1">
              <div>
                <i class="fa-regular fa-calendar-check"></i>
                {{ $t("reservation_by") }}
              </div>
              <span class="text-black">{{ userName }}</span>
            </h5>
            <h5 class="d-flex flex-column gap-1">
              <div>
                <i class="fa-regular fa-calendar-check"></i>
                {{ $t("reservation_confirmation_number") }}
              </div>
              <span class="text-black">{{
                hotelsDetials.confirmation_number
              }}</span>
            </h5>
            <h5 class="d-flex flex-column gap-1">
              <div>
                <i class="fa-regular fa-calendar-check"></i>

                {{ $t("create_reservation_date") }}
              </div>
              <span class="text-black">{{ hotelsDetials.created_at }}</span>
            </h5>
          </div>
        </div>
        <QrCodeGenerator />
      </div>
      <hr />
      <div class="under-line">
        <div class="hed">
          <h4 class="fw-bold">
            {{ $t("payment details") }}
          </h4>
        </div>
        <div class="pay mt-2">
          <h5 class="text-wrap mb-0">
            {{ hotelsDetials.rooms_number }} {{ $t("rooms") }} -
            {{ hotelsDetials.number_of_nights }} {{ $t("number_night") }}
          </h5>
          <p class="fw-bold fs-6 text-black mb-2">
            {{ Math.ceil(convertedPrice(hotelsDetials.overall_selling_price)) }}
            {{ coinCode }}
          </p>
        </div>
      </div>
      <div class="under-line">
        <div class="pay-1 mt-2">
          <h5 class="text-wrap">
            {{ $t("The total includes value added tax") }}
          </h5>
          <p class="fw-bold fs-6 text-black w-50">
            {{ Math.ceil(convertedPrice(hotelsDetials.overall_selling_price)) }}
            {{ coinCode }}
          </p>
        </div>
      </div>
    </div>
    <div class="last-card">
      <div class="why">
        <h4>
          {{ $t("help") }}
        </h4>
        <p class="fs-6">
          {{ $t("call_us") }}
        </p>
        <hr />
        <div class="icon">
          <span><i class="fa-solid fa-envelope"></i></span>
          <h4>
            <a :href="`mailto:${data.email}`">{{ data.email }}</a>
          </h4>
        </div>
        <div class="icon">
          <span><i class="fa-solid fa-phone"></i></span>
          <h4>
            <a :href="`tel:${data.phone}`">{{ data.phone }}</a>
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrCodeGenerator from "./QrCodeGenerator.vue";
import { organizationSettings } from "@/store/modules/organizationSettings";
import { mapState } from "pinia";

// import moment from "moment";
// import Swal from "sweetalert2";
// import MessageDialog from "@/components/Dialogs/messageDialog.vue";
// import whatsAppDialog from "@/components/Dialogs/whatsAppDialog.vue";

export default {
  name: "sidebar-hotel",
  props: {
    hotelsDetials: Object,
    rooms: Array,
  },
  data() {
    return {
      coinCode:
        localStorage.getItem("coinCode") == null
          ? "USD"
          : localStorage.getItem("coinCode"),
      userName: JSON.parse(localStorage.getItem("user")).name,
      numberOfNights: 0,
      to: "",
      from: "",
    };
  },
  computed: {
    ...mapState(organizationSettings, ["data"]),
  },
  components: {
    // Datepicker: VueDatepickerUi,
    // MessageDialog,
    // whatsAppDialog,
    QrCodeGenerator,
  },
  methods: {
    addOneDay(date) {
      const newDate = new Date(date);
      newDate.setDate(newDate.getDate() + 2);
      return newDate.toISOString().split("T")[0];
    },
    calculateNumberOfNights() {
      const checkInDate = new Date(this.hotelsDetials.check_in);
      const checkOutDate = new Date(this.hotelsDetials.check_out);

      // Calculate the time difference in milliseconds
      const timeDiff = checkOutDate.getTime() - checkInDate.getTime();

      // Calculate the number of nights
      const nights = Math.ceil(timeDiff / (1000 * 3600 * 24));

      // Update the numberOfNights data property
      this.numberOfNights = nights;
    },
  },
  mounted() {
    this.calculateNumberOfNights();
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
.btns {
  i {
    &:hover {
      background-color: $PrimaryColor !important;
    }
  }
}
.SecondButton {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  font-size: 1.2rem;
  margin: 0 5px;
  font-family: "bold";
  padding: 0.6rem 0.8rem;
  transition: 0.7s ease-in-out;
}
.width-fit-content {
  width: fit-content;
  margin-inline-start: auto;
}
</style>
