<template>
  <div class="flight selects hotelSelect">
    <div class="flex-content">
      <div class="form-group">
        <img class="icon" src="@/assets/media/image/icon/nationality.png" />
        <Multiselect
          v-model="nationalityValue"
          :options="nationalities"
          label="title"
          track-by="id"
          :clear-on-select="true"
          :placeholder="$t('choose_nationality')"
          select-label=""
          :hide-selected="true"
          @keyup="searchNationality"
        >
        </Multiselect>
      </div>
    </div>

    <div class="flex-content">
      <div class="form-group">
        <div class="card flex justify-content-center">
          <Calendar
            v-model="selectedDate"
            selectionMode="range"
            :manualInput="true"
            :minDate="new Date()"
            showIcon
          />
        </div>
      </div>
    </div>

    <div class="flex-content">
      <div class="form-group">
        <Multiselect
          v-model="package_type_id"
          :options="packages"
          label="title"
          track-by="id"
          :clear-on-select="true"
          :placeholder="$t('package_type')"
          select-label=""
          :hide-selected="true"
          @open="searchPackages"
        >
        </Multiselect>
      </div>
    </div>
    <div class="flex-content" v-if="reservation_active == 'tourism'">
      <div class="form-group">
        <img class="icon" src="@/assets/media/image/icon/address.png" />
        <Multiselect
          v-model="country_value"
          :options="countries"
          label="title"
          track-by="id"
          :clear-on-select="true"
          :placeholder="$t('What is your destination ?')"
          select-label=""
          :hide-selected="true"
          @keyup="searchCountries"
        >
        </Multiselect>
      </div>
    </div>
    <div class="flex-content mt-3 mt-md-0">
      <button
        type="button"
        @click="sendPackageData"
        class="btn SecondaryButton"
      >
        {{ $t("search now") }}
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import Calendar from "primevue/calendar";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      nationalities: [],
      nationalityValue: "",
      citiesSearch: [],
      selectedDate:
        (new Date(), new Date(new Date().getTime() + 24 * 60 * 60 * 1000)),
      packages: [],
      package_type_id: "",
      searchValue: "",
      countries: [],
      country_value: {},
      package_tourism_type: this.$route.params.type
    };
  },
  props: {
    dataUmrah: Object,
    reservation_active: String
  },
  methods: {
    searchCountries(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => (this.countries = res.data.data.data))
        .catch((err) => console.log(err));
    },

    searchPackages() {
      this.package_type = {package_type: this.reservation_active == 'tourism' ? 2 : 1}
      axios
        .post(`/fetch_package_types`, this.package_type)
        .then((res) => (this.packages = res.data.data))
        .catch((err) => console.log(err));
    },

    searchCity(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/search_home_hotels`, searchText)
        .then((res) => (this.citiesSearch = res.data.data.cities))
        .catch((err) => console.log(err));
      // console.log(searchText);
    },

    sendCityId(city_id) {
      this.city_id = city_id;
      this.citiesSearch = [];
      // console.log(this.cityTitle)
      // console.log(this.cityId)
    },
    searchNationality(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => (this.nationalities = res.data.data.data))
        .catch((err) => console.log(err));
      // console.log(searchText);
      // console.log(this.nationalities);
    },
    sendPackageData() {
      const packageData = {
        country_id: this.reservation_active == 'tourism' ? this.country_value.id : 598,
        // city_id: this.country_value.id,
        from_date:
          this.selectedDate != null
            ? this.selectedDate[0] != null
              ? moment(this.selectedDate[0]).format("DD-MM-YYYY")
              : ""
            : "",
        to_date:
          this.selectedDate != null
            ? this.selectedDate[1] != null
              ? moment(this.selectedDate[1]).format("DD-MM-YYYY")
              : ""
            : "",
        package_type_id:
          this.package_type_id != undefined
            ? this.package_type_id.id != undefined
              ? this.package_type_id.id
              : ""
            : "",
      };
      const umrahData = {
        package: this.package_type_id,
        country_id: this.reservation_active == 'tourism' ? this.country_value.id : 598,
        country_value: this.country_value,
        nationalityValue: this.nationalityValue,
        from_date:
          this.selectedDate != null
            ? this.selectedDate[0] != null
              ? moment(this.selectedDate[0]).format("DD-MM-YYYY")
              : ""
            : "",
        to_date:
          this.selectedDate != null
            ? this.selectedDate[1] != null
              ? moment(this.selectedDate[1]).format("DD-MM-YYYY")
              : ""
            : "",
      };

      if (packageData.from_date == "") {
        Swal.fire({
          title: "",
          text: "choose check_in",
          icon: "error",
        });
      } else if (packageData.to_date == "") {
        Swal.fire({
          title: "",
          text: "choose check_out",
          icon: "error",
        });
      } else if (this.nationalityValue == undefined) {
        Swal.fire({
          title: "",
          text: this.$t("Choose nationality first"),
          icon: "error",
        });
      } else if (packageData.package_type_id == "") {
        Swal.fire({
          title: "",
          text: "choose package type",
          icon: "error",
        });
      } else {
        sessionStorage.setItem("packageData", JSON.stringify(packageData));
        sessionStorage.setItem("searchData", JSON.stringify(umrahData));
        if(this.$route.params == "tourism") {
          this.$router.push(`/search/package/${this.reservation_active}`);
        }
        else {
          this.$router.push(`/search/package/${this.reservation_active}`);
        }
        this.$emit("fetchData");
      }
    },
    fetch_search_data() {
      let searchData =
        JSON.parse(sessionStorage.getItem("searchData")) == null
          ? {}
          : JSON.parse(sessionStorage.getItem("searchData"));
      this.package_type_id = searchData.package;
      this.country_value = searchData.country_value;
      // this.city_id = searchData.city_id;
      this.country_id = searchData.country_id;
      this.nationalityValue = searchData.nationalityValue;
      this.selectedDate =
        JSON.parse(sessionStorage.getItem("searchData")) == null
          ? [new Date(), new Date(new Date().getTime() + 24 * 60 * 60 * 1000)]
          : [
              JSON.parse(sessionStorage.getItem("searchData")).from_date,
              JSON.parse(sessionStorage.getItem("searchData")).to_date,
            ];
    },
  },
  created() {
    // this.searchPackages();
    this.fetch_search_data();
  },
  components: {
    Calendar,
    Multiselect,
  },
};
</script>
