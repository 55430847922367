<template>
  <div class="title my-2">
    <h3>{{ $t("my_reservation") }}</h3>
    <p>{{ $t("View and manage all your reservations") }}</p>
  </div>
  <form>
    <div class="row">
      <div class="col-lg-6 col-md-12 col-12 my-2">
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            :placeholder="$t('search by reservation name')"
          />
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12 my-2">
        <div class="form-group">
          <Multiselect
            v-model="travelPackagesValue"
            :options="travelPackages.map((travel) => travel.id)"
            :custom-label="
              (opt) => travelPackages.find((x) => x.id == opt).title
            "
            :clear-on-select="true"
            :placeholder="$t('travelPackages')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12 my-2">
        <div class="form-group">
          <Multiselect
            v-model="statusValue"
            :options="allStatus.map((status) => status.id)"
            :custom-label="(opt) => allStatus.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('allStatus')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="all_travels">
      <div
        class="travel p-3"
        v-for="(travel, index) in allPackages"
        :key="index"
      >
        <div class="main-travel" @click="goToDetails(travel.id)">
          <div class="image">
            <img :src="travel?.package?.images[0]?.image" alt="photo" />
          </div>
          <div class="content px-3">
            <div class="travel_title">
              <h5 class="fw-bold">{{ travel?.package?.title }}</h5>
              <div class="travel_date">
                <i class="fa-solid fa-calendar-days"></i>
                <span>{{ $t("reservation_date") }}: </span>{{ travel.date }}
              </div>
            </div>
            <hr />
            <div class="details">
              <div class="row">
                <div class="col-12">
                  <div class="d-flex align-items-center">
                    <i class="fa-solid fa-location-dot"></i>
                    <div class="d-flex align-items-center">
                      <div class="d-flex align-items-center">
                        <span>{{ travel?.package?.launch_city?.title }}</span>
                        <img
                          src="@/assets/media/image/umrah/icons/arrow-2.svg"
                          alt=""
                          class="mx-2"
                        />
                      </div>
                      <div
                        class="d-flex align-items-center"
                        v-for="(dist, index) in travel?.package?.destinations"
                        :key="index"
                      >
                        <span>{{ dist.city.title }}</span>
                        <img
                          src="@/assets/media/image/umrah/icons/arrow-2.svg"
                          alt=""
                          class="mx-2"
                          v-if="
                            index != travel?.package?.destinations.length - 1
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="">
                    <i class="fa-solid fa-calendar-days"></i>
                    <span>{{ $t("Arrival date") }}: </span>
                    {{
                      travel?.package?.luanch_date
                    }}
                  </div>
                </div>
                <div class="col-6">
                  <div class="">
                    <i class="fa-solid fa-money-bill"></i>
                    <span>{{ $t("Price before tax") }}: </span>
                    {{ travel.total_price }}
                  </div>
                </div>
                <div class="col-6">
                  <div class="">
                    <i class="fa-solid fa-calendar-days"></i>
                    <span>{{ $t("Departure Date") }}: </span>
                    {{
                      travel?.package?.arrival_date
                    }}
                  </div>
                </div>
                <div class="col-6">
                  <div class="">
                    <i class="fa-solid fa-money-bill"></i>
                    <span>{{ $t("Price after tax") }}: </span>
                    {{ travel.total_price_after_tax }}
                  </div>
                </div>
                <div class="col-12">
                  <div class="">
                    <i class="fa-solid fa-moon"></i>
                    <span>{{ $t("number_of_nights") }}: </span>
                    {{ travel?.package?.day_count }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="rececipt">
          <button
            type="button"
            class="btn btn-primary receipt"
            data-bs-toggle="modal"
            :data-bs-target="`#SendReceipt${travel.id}`"
            v-if="
              travel.payment_method == 1 &&
              (travel.receipts.length > 0
                ? travel.receipts[0].image == ''
                : false || travel.receipts.length == 0)
            "
          >
            {{ $t("Raise_the_originals") }}
          </button>

          <button
            type="button"
            class="btn btn-success"
            data-bs-toggle="modal"
            :data-bs-target="`#ShowReceipt${travel.id}`"
            v-if="
              travel.receipts.length > 0
                ? travel.receipts[0].image != ''
                : false
            "
          >
            {{ $t("show reciept") }}
          </button>
        </div>
        <sendRececipt
          :book_id="travel.id"
          :book_type="3"
          @fetchBookPackages="fetchBookPackages"
        />
        <showReceipt
          :book_id="travel.id"
          :book_type="3"
          :image="travel.receipts.length > 0 ? travel.receipts[0].image : ''"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import sendRececipt from "@/components/sendRececipt/sendReceipt.vue";
import showReceipt from "@/components/sendRececipt/showReceipt.vue";

export default {
  components: { Multiselect, sendRececipt, showReceipt },
  data() {
    return {
      travelPackages: [],
      travelPackagesValue: "",
      allStatus: [],
      statusValue: "",
      allPackages: [],
      image: "",
    };
  },
  methods: {
    fetchBookPackages() {
      axios
        .get(`/fetch_my_book_package`)
        .then(({ data }) => {
          this.allPackages = data.data.data;
          console.log(this.allPackages, "oooooooooooooooo");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    goToDetails(id) {
      this.$router.push(`/umrahBookingProfile/${id}`);
    },
  },
  mounted() {
    this.fetchBookPackages();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.title {
  h3 {
    color: #0b1427;
    font-size: 20px;
    font-family: "bold";
  }
  p {
    font-size: 16px;
    color: #656b78;
  }
}

.travel {
  border: 1px solid #d0d2d7;
  border-radius: 8px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  .image {
    width: 241px;
    overflow: hidden;
    border-radius: 10px;
    height: 200px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    position: relative;
    .travel_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h5 {
        color: #0b1427;
        font-size: 24px;
        margin-bottom: 0;
      }
      .travel_date {
        color: #0b1427;
        font-size: 12px;
        i {
          color: $PrimaryColor;
          margin-inline-end: 5px;
        }
        span {
          color: #656b78;
        }
      }
    }
    .details {
      font-size: 12px;
      i {
        margin-inline-end: 5px;
      }
      i,
      span {
        color: #656b78;
      }
      .row > div {
        margin-bottom: 10px;
      }
    }
    .arrow_div {
      background-color: $PrimaryColor;
      color: #fff;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;
      position: absolute;
      left: 20px;
      bottom: 20px;
      cursor: pointer;
      a {
        color: #fff;
      }
    }
  }
}
button.btn.btn-primary.receipt {
  background-color: #bc980d;
  border: unset;
  font-family: "bold";
}
</style>
