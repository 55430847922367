<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="proile_main">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-12 col-12">
          <sidebar />
        </div>
        <div class="col-lg-9 col-md-12 col-12">
          <personal-data />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import sidebar from "@/components/profile/sidebar.vue";
import Loading from "@/components/LottieFiles/flightSearchLoading.vue";
import personalData from "@/components/profile/prsonalData.vue";

  //   import axios from "axios";
  //   import Swal from "sweetalert2";

  export default {
    name: "flight-booking",
    components: {
      sidebar,
      Loading,
      personalData,
    },
    data() {
      return {
        loading: false,
      };
    },
    methods: {},
    created() {},
  };
</script>

<!-- <style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  @import "./_personalData.scss";
</style> -->
