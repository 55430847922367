<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="proile_main">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-12 col-12">
          <sidebar />
        </div>
        <div class="col-lg-8 col-md-12 col-12">
          <div class="blocks shadow-sm">
            <div class="block">
              <div class="content">
                <h4>{{ $t("password") }}</h4>
                <h5>############</h5>
                <span @click="toggleForm">{{
                  showForm ? $t("cancel") : $t("edit")
                }}</span>
              </div>

              <form v-if="showForm" @submit.prevent="updatedPassword()">
                <div class="input-group passord">
                  <label class="form-label">{{ $t("Old Password") }}</label>
                  <input
                    v-if="showOldPassword"
                    type="text"
                    class="form-control"
                    id="password"
                    v-model="oldPassword"
                    :placeholder="$t('enter password')"
                  />
                  <input
                    v-else
                    class="form-control"
                    v-model="oldPassword"
                    type="password"
                    id="password"
                  />
                  <i
                    class="fa-solid"
                    :class="{
                      'fa-eye-slash': !showOldPassword,
                      'fa-eye': showOldPassword,
                    }"
                    @click="toggleShowOldPass"
                  ></i>
                </div>
                <div class="input-group passord">
                  <label class="form-label">{{ $t("New Password") }}</label>
                  <input
                    v-if="showNewPassword"
                    type="text"
                    class="form-control"
                    id="newpassword"
                    v-model="newPassword"
                    :placeholder="$t('enter password')"
                  />
                  <input
                    v-else
                    type="password"
                    class="form-control"
                    v-model="newPassword"
                    id="newpassword"
                  />
                  <i
                    class="fa-solid"
                    :class="{
                      'fa-eye-slash': !showNewPassword,
                      'fa-eye': showNewPassword,
                    }"
                    @click="toggleShowNewPass"
                  ></i>
                </div>

                <button class="mt-2 btn PrimaryButton" type="submit">
                  {{ $t("save") }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import sidebar from "@/components/profile/sidebar.vue";
import Loading from "@/components/LottieFiles/flightSearchLoading.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "travel-perfernce",
  components: {
    sidebar,
    Loading,
  },
  data() {
    return {
      loading: false,
      showForm: false,
      //   updatedPassword: "",
      oldPassword: "",
      newPassword: "",
      showOldPassword: false,
      showNewPassword: false,
    };
  },
  methods: {
    toggleForm() {
      this.showForm = !this.showForm;
    },
    toggleShowOldPass() {
      this.showOldPassword = !this.showOldPassword;
    },
    toggleShowNewPass() {
      this.showNewPassword = !this.showNewPassword;
    },
    updatedPassword() {
      const requestData = {
        old_password: this.oldPassword,
        new_password: this.newPassword,
      };
      this.loading = true;
      axios
        .post("/change_password", requestData)
        .then(({ data }) => {
          this.new = data;
          this.loading = false; //the loading ended
          Swal.fire({
            title: "Success!",
            text: "Password changed successfully.",
            icon: "success",
          });
          // console.log(this.price)
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.loading = false; //the loading ended
        });
    },
  },
  created() {},
};
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_securitySetting.scss";
</style> -->
