<template>
  <section class="hotel_detials">
    <div class="container-fluid">
      <div v-if="loading" class="loading">
        <Loading />
      </div>
      <headerImages :hotelData="hotelData == null ? null : hotelData" />
      <Images
        :images="hotelData == null ? null : hotelData.images"
        :hotelData="hotelData == null ? null : hotelData"
      />

      <div class="container mt-2">
        <form>
          <!-- <h5>{{$t("edit_search")}}</h5> -->
          <div class="selects row">
            <div class="col-12 col-md-6 col-lg-3 my-2">
              <div class="form-group">
                <input
                  type="search"
                  class="form-control"
                  :placeholder="$t('choose_hotel')"
                  v-model="searchValue"
                  @keyup="searchHotels"
                />
                <div
                  class="resultSearch"
                  v-if="
                    searchValue == null
                      ? null
                      : searchValue.length > 2 &&
                        (citiesSearch.length || hotelsSearch.length)
                  "
                >
                  <h6 class="head_search" v-if="citiesSearch.length">
                    {{ $t("The Cities") }}
                  </h6>
                  <ul class="cities" v-if="citiesSearch.length">
                    <li
                      v-for="(city, index) in citiesSearch"
                      :key="index"
                      @click="
                        sendCityId(
                          city.title,
                          city.id,
                          city.country_code,
                          city.country_title
                        )
                      "
                    >
                      <i class="fa-solid fa-location-dot"></i>
                      <div class="texts">
                        <p class="title">{{ city.title }}</p>
                        <p class="country">{{ city.country_title }}</p>
                      </div>
                    </li>
                  </ul>
                  <h6 class="head_search" v-if="hotelsSearch.length">
                    {{ $t("Hotels") }}
                  </h6>
                  <ul class="hotels" v-if="hotelsSearch.length">
                    <li
                      v-for="(hotel, index) in hotelsSearch"
                      :key="index"
                      @click="
                        sendHotelName(
                          hotel.title,
                          hotel.hotel_code,
                          hotel.city_id,
                          hotel.country_code,
                          hotel.country_title
                        )
                      "
                    >
                      <img
                        :src="hotel.image"
                        class="hotel_image"
                        :alt="hotel.title"
                      />
                      <div class="texts">
                        <p class="title">{{ hotel.title }}</p>
                        <p class="country">
                          {{ hotel.country_title }} , {{ hotel.city_title }}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-2 my-2">
              <div class="form-group">
                <Multiselect
                  v-model="nationalityValue"
                  :options="nationalities"
                  label="title"
                  track-by="id"
                  :clear-on-select="true"
                  :placeholder="$t('choose_nationality')"
                  select-label=""
                  :hide-selected="true"
                  @keyup="searchNationality"
                >
                </Multiselect>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-3 my-2">
              <div class="form-group">
                <div class="card flex justify-content-center">
                  <Calendar
                    v-model="selectedDate"
                    selectionMode="range"
                    :manualInput="true"
                    showIcon
                  />
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-2 my-2">
              <div class="form-group">
                <div class="dropdown">
                  <button
                    type="button"
                    class="btn btn-primary dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                  >
                    {{ $t("rooms") }} {{ rooms.length }} - {{ $t("Adults") }}
                    {{ adults }} - {{ $t("childrens") }} {{ childrens }}
                  </button>
                  <div class="dropdown-menu">
                    <div class="list">
                      <span>{{ $t("Adults") }}</span>
                      <div class="number">
                        <button
                          type="button"
                          class="btn minus"
                          @click="minusQuantityAdults()"
                        >
                          <i class="fa-solid fa-minus"></i>
                        </button>
                        <p class="count">{{ adults }}</p>
                        <button
                          type="button"
                          class="btn add"
                          @click="addQuantityAdults()"
                        >
                          <i class="fa-solid fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="list">
                      <span>{{ $t("childrens") }}</span>
                      <div class="number">
                        <button
                          type="button"
                          class="btn minus"
                          @click="minusQuantityChildrens()"
                        >
                          <i class="fa-solid fa-minus"></i>
                        </button>
                        <p class="count">{{ childrens }}</p>
                        <button
                          type="button"
                          class="btn add"
                          @click="addQuantityChildrens()"
                        >
                          <i class="fa-solid fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="list">
                      <span>{{ $t("rooms") }}</span>
                      <div class="number">
                        <button
                          type="button"
                          class="btn minus"
                          @click="minusQuantityRooms()"
                        >
                          <i class="fa-solid fa-minus"></i>
                        </button>
                        <p class="count">{{ rooms }}</p>
                        <button
                          type="button"
                          class="btn add"
                          @click="addQuantityRooms()"
                        >
                          <i class="fa-solid fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 col-12 my-2">
              <button
                type="button"
                @click="edit_search()"
                class="btn PrimaryButton search"
              >
                {{ $t("edit search") }}
              </button>
            </div>
          </div>
        </form>
      </div>

      <TabMenu
        :hotelData="hotelData == null ? null : hotelData"
        :ScrollUp="ScrollUp"
      />
    </div>
  </section>
</template>

<script>
import headerImages from "@/components/hotelDetials/headerImages.vue";
// import Header from '@/components/home/Header.vue';
import Images from "@/components/hotelDetials/images.vue";
import TabMenu from "@/components/hotelDetials/tabs.vue";
// import Swal from "sweetalert2";
import moment from "moment";

import axios from "axios";
import Loading from "@/components/LottieFiles/flightSearchLoading.vue";
import Multiselect from "vue-multiselect";
import Calendar from "primevue/calendar";

export default {
  name: "hotel-detials",
  components: {
    headerImages,
    Images,
    TabMenu,
    Loading,
    Calendar,
    Multiselect,
  },
  data() {
    return {
      // page_params: this.$route.params.page,
      hotelId: this.$route.params.id,
      hotelData: {
        images: [],
      },
      loading: false,
      provider_id: JSON.parse(localStorage.getItem("hotelData"))?.meta_data
        ?.provider_id,
      citiesSearch: [],
      countryValue: "",
      searchValue:
        JSON.parse(localStorage.getItem("hotelDataSearch")) == null
          ? ""
          : JSON.parse(localStorage.getItem("hotelDataSearch"))?.hotel_name ==
            null
          ? JSON.parse(localStorage.getItem("hotelDataSearch"))?.city_name
          : JSON.parse(localStorage.getItem("hotelDataSearch"))?.hotel_name,
      hotel_name: "",
      cityValue: [],
      nationalities: [],
      nationalityValue: {
        id:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
            ? ""
            : JSON.parse(localStorage.getItem("hotelDataSearch"))
                .nationality_id,
        title:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
            ? ""
            : JSON.parse(localStorage.getItem("hotelDataSearch"))
                .nationality_name,
        code:
          JSON.parse(localStorage.getItem("hotelDataSearch")) == null
            ? ""
            : JSON.parse(localStorage.getItem("hotelDataSearch"))
                .nationality_code,
      },
      hotelsSearch: [],
      cityId:
        JSON.parse(localStorage.getItem("hotelDataSearch")) == null
          ? ""
          : JSON.parse(localStorage.getItem("hotelDataSearch"))?.city_id,
      cityTitle:
        JSON.parse(localStorage.getItem("hotelDataSearch")) == null
          ? ""
          : JSON.parse(localStorage.getItem("hotelDataSearch"))?.city_name,
      country_title:
        JSON.parse(localStorage.getItem("hotelDataSearch")) == null
          ? ""
          : JSON.parse(localStorage.getItem("hotelDataSearch"))?.country_name,
      country_code:
        JSON.parse(localStorage.getItem("hotelDataSearch")) == null
          ? ""
          : JSON.parse(localStorage.getItem("hotelDataSearch"))?.country_code,
      hotelValue: "",

      adults:
        JSON.parse(localStorage.getItem("hotelDataSearch")) == null
          ? 1
          : JSON.parse(localStorage.getItem("hotelDataSearch"))?.rooms_guests[0]
              ?.adults_count,
      childrens:
        JSON.parse(localStorage.getItem("hotelDataSearch")) == null
          ? 1
          : JSON.parse(localStorage.getItem("hotelDataSearch"))?.rooms_guests[0]
              ?.children_count,
      rooms:
        JSON.parse(localStorage.getItem("hotelDataSearch")) == null
          ? 1
          : JSON.parse(localStorage.getItem("hotelDataSearch"))?.no_of_rooms,
      searchValueLaunch: "",
      searchValueReturn: "",
      edit_form: false,
      check_in:
        JSON.parse(localStorage.getItem("hotelDataSearch")) == null
          ? new Date()
          : JSON.parse(localStorage.getItem("hotelDataSearch"))?.check_in,
      check_out:
        JSON.parse(localStorage.getItem("hotelDataSearch")) == null
          ? null
          : JSON.parse(localStorage.getItem("hotelDataSearch"))?.check_out,
      selectedDate:
        JSON.parse(localStorage.getItem("hotelDataSearch")) == null
          ? [new Date(), null]
          : [
              new Date(
                moment(
                  JSON.parse(localStorage.getItem("hotelDataSearch"))?.check_in
                ).format("MMM DD YYYY")
              ),
              new Date(
                moment(
                  JSON.parse(localStorage.getItem("hotelDataSearch"))?.check_out
                ).format("MMM DD YYYY")
              ),
            ],
      pageParams: this.$route.params.page,
    };
  },
  methods: {
    fetchHotelDetails() {
      this.loading = true; //the loading begin
      axios
        .post("/fetch_new_hotel_details", {
          code: parseInt(this.hotelId),
          provider_id: 1,
        })
        .then(({ data }) => {
          this.hotelData = data.data;
          // console.log(this.hotelData);
          this.loading = false; //the loading ended
        })
        .catch((error) => {
          this.loading = false; //the loading ended
          return error;
        });
    },

    searchNationality(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => (this.nationalities = res.data.data.data))
        .catch((err) => console.log(err));
    },
    searchHotels(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/search_home_hotels`, searchText)
        .then(
          (res) => (
            (this.hotelsSearch = res.data.data.hotels),
            (this.citiesSearch = res.data.data.cities)
          )
        )
        .catch((err) => console.log(err));
      // console.log(searchText);
    },

    // fetch hotels
    sendHotelName(hotelName, hotelID, cityId, country_code, country_title) {
      this.hotelName = hotelName;
      this.hotelID = hotelID;
      this.cityTitle = null;
      this.searchValue = hotelName;
      this.country_code = country_code;
      this.country_title = country_title;
      this.cityId = cityId;
      this.citiesSearch.length = [];
      this.hotelsSearch = [];
      // console.log(this.hotelName)
      // console.log(this.hotelID)
    },
    sendCityId(cityTitle, cityId, country_code, country_title) {
      this.cityId = cityId;
      this.cityTitle = cityTitle;
      this.searchValue = cityTitle;
      this.country_code = country_code;
      this.country_title = country_title;
      this.hotelName = null;
      this.hotelID = null;
      this.citiesSearch = [];
      this.hotelsSearch = [];
      // console.log(this.cityTitle)
      // console.log(this.cityId)
    },
    // Adults Minus && Add
    minusQuantityAdults() {
      if (this.adults > 1) {
        this.adults -= 1;
      }
    },
    addQuantityAdults() {
      this.adults += 1;
    },

    // childrens Minus && Add
    minusQuantityChildrens() {
      if (this.childrens > 0) {
        this.childrens -= 1;
      }
    },
    addQuantityChildrens() {
      this.childrens += 1;
    },

    // Rooms Minus && Add
    minusQuantityRooms() {
      if (this.rooms > 1) {
        this.rooms -= 1;
      }
    },
    addQuantityRooms() {
      this.rooms += 1;
    },
    edit_search() {
      let fetchedHotel = {
        city_id: this.cityId,
        city_name: this.cityTitle,
        country_code: this.country_code,
        country_name: this.country_title,
        nationality_name: this.nationalityValue.title,
        nationality_id: this.nationalityValue.id,
        nationality_code: this.nationalityValue.code,

        passenger_country_of_residence_code: this.nationalityValue.code,
        no_of_rooms: this.rooms,
        converter_status: 1,
        // test: "test",

        check_in: moment(this.selectedDate[0]).format("YYYY-MM-DD"),
        check_out: moment(this.selectedDate[1]).format("YYYY-MM-DD"),
        rooms_guests: [
          {
            rate_basis: "1",
            adults_count: this.adults,
            children_count: this.childrens,
            children_ages: Array.from(
              {
                length: this.hotelData == null ? 0 : this.hotelData.childrens,
              },
              () => 7
            ),
          },
        ],
        meta_data: {
          credential_id: 2,
          provider_id: 2,
        },
        city_title: this.cityTitle,
      };

      localStorage.setItem("hotelDataSearch", JSON.stringify(fetchedHotel));
      this.$router.push("/hotelSearchNew");
    },

    ScrollUp() {
      window.scrollTo({
        top: 300,
        behavior: "smooth", // Add smooth scrolling behavior
      });
    },
  },
  created() {
    this.fetchHotelDetails();
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "./profile/_hotelDetials.scss";
</style> -->
