<template>
  <section class="news_sec mb-5">
    <div class="container">
      <div class="d-flex align-items-center justify-content-between flex-wrap mb-3">
        <div class="title">
          <h2 class="fw-bold mx-0">
            {{ $t("Learn about the latest and most up-to-date news") }}
          </h2>
          <p>
            {{
              $t(
                "Travelers looking for the best places in the world also book here"
              )
            }}
          </p>
        </div>
        <router-link to="/blogs" class="SecondButton rounded">{{
          $t("see more")
        }}</router-link>
      </div>
      <div class="blog-grid">
        <router-link
          class="blog-card rounded"
          v-for="(blog, index) in blogs"
          :key="index"
          :to="`/blog/${blog.id}`"
        >
          <div class="overlay">
            <p class="hashtag">{{ blog?.hashtag_blogs?.title }}</p>

            <h4 class="fw-bold text-white fs-6 text-wrap">{{ blog.title }}</h4>
            <div class="date d-flex align-items-center">
              <img src="@/assets/media/image/calendar.svg" alt="" width="20" />
              <p class="date mb-0 text-light text-emphasis">
                {{ blog.date }}
              </p>
            </div>
          </div>
          <img :src="blog.image" class="card-img-top" />
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";
import axios from "axios";
export default {
  name: "NewsSection",
  data() {
    return {
      blogs: [],
    };
  },
  methods: {
    formatDate(value) {
      return moment(value).format("MMMM DD");
    },
    async fetchBolgs() {
      await axios.get("/blogs").then(({ data }) => {
        this.blogs = data.data.data.slice(0, 3);
      });
    },
  },
  async created() {
    await this.fetchBolgs();
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_News.scss";
</style> -->
