<template>
  <Loading v-if="loading" />
  <section class="rooms_sec">
    <div class="container">
      <!-- <h2>{{ $t("rooms_options") }}</h2> -->
      <div class="rooms_card">
        <div class="card" v-if="roomPrices.length">
          <ul class="nav nav-pills" id="pills-tab" role="tablist">
            <li
              class="nav-item"
              role="presentation"
              v-for="(price, indexRoomPrice) in roomPrices"
              :key="indexRoomPrice"
            >
              <!-- {{ price }} -->
              <button
                class="nav-link"
                :class="{ active: indexRoomPrice == 0 }"
                @click="fetchProviderAvailableRooms(price)"
                :id="`#room-tab${indexRoomPrice}`"
                data-bs-toggle="pill"
                :data-bs-target="`#room${indexRoomPrice}`"
                type="button"
                role="tab"
                :aria-controls="`#room${indexRoomPrice}`"
                aria-selected="true"
              >
                {{ $t("start from") + " " + price.min_price }}
              </button>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade"
              v-for="(price, indexRoomPrice) in roomPrices"
              :key="indexRoomPrice"
              :class="{
                active: indexRoomPrice == 0,
                show: indexRoomPrice == 0,
              }"
              :id="`room${indexRoomPrice == undefined ? 0 : indexRoomPrice}`"
              role="tabpanel"
              :aria-labelledby="`#room-tab${
                indexRoomPrice == undefined ? 0 : indexRoomPrice
              }`"
              tabindex="0"
            >
              <div class="row">
                <div
                  class="col-12 d-flex justify-content-end"
                  v-if="no_of_rooms > 1"
                >
                  <button
                    class="btn btn-booking book_all"
                    type="button"
                    @click="organizeRoomsData()"
                  >
                    {{ $t("Book now") }}
                  </button>
                </div>
                <!-- <h4 v-if="!roomsData.length" class="text-center">{{ $t("rooms don't exist") }}</h4> -->
                <!-- {{ roomsData }} -->
                <div
                  class="col-12"
                  v-for="room in roomsData"
                  :key="room.roomNumber"
                >
                  <div
                    class="room_card"
                    v-for="(roomPrice, index) in room.room_rate_basis"
                    :key="index"
                  >
                    <div class="image">
                      <img :src="DefaultImage" alt="" />
                      <!-- <Loading /> -->
                      <div class="layout"></div>
                    </div>
                    <div class="title">
                      <h5 class="card_title">
                        <span class="room_name">{{ room.name }}</span>
                      </h5>
                      <div class="room_details">
                        <ul>
                          <li>
                            <i class="fa-regular fa-circle-check"></i>
                            <span class="meal"
                              >({{ roomPrice.description }})</span
                            >
                          </li>
                          <li class="policies_click">
                            <a
                              :href="`#popup${room.id}`"
                              @click="
                                checkRoomCancellationPolicies(
                                  index,
                                  'cancel',
                                  room,
                                  indexRoomPrice
                                )
                              "
                              @mouseenter="isHover(index, 'cancel', room)"
                              @mouseleave="hoverData = []"
                              ><i class="fa-regular fa-circle-check"></i>
                              <span>{{ $t("Cancellation Policies") }}</span></a
                            >
                          </li>
                        </ul>
                        <div :id="`popup${room.id}`" class="popup">
                          <div
                            class="d-flex align-items-center justify-content-between"
                          >
                            <h4 class="title py-0">
                              {{ $t("Cancellation Policies") }}
                            </h4>
                            <a href="#" class="close">&times;</a>
                          </div>
                          <h5 class="title">
                            {{
                              cancellation_rate_basis == null
                                ? ""
                                : cancellation_rate_basis.description
                            }}
                          </h5>
                          <div
                            v-if="
                              cancellation_rate_basis == null ||
                              cancellation_rate_basis.cancellation_rules
                            "
                          >
                            <span
                              >{{ $t("cancel_free_from") }}
                              {{
                                cancellation_rate_basis == null
                                  ? ""
                                  : cancellation_rate_basis
                                      .cancellation_rules[0].from_date
                              }}
                              {{ $t("to") }}
                              {{
                                cancellation_rate_basis == null
                                  ? ""
                                  : cancellation_rate_basis
                                      .cancellation_rules[0].to_date
                              }}</span
                            >
                            <span
                              >{{ $t("cancel_from") }}
                              {{
                                cancellation_rate_basis == null
                                  ? ""
                                  : cancellation_rate_basis
                                      .cancellation_rules[1] == null
                                  ? ""
                                  : cancellation_rate_basis
                                      .cancellation_rules[1].from_date
                              }}
                              {{ $t("to") }}
                              {{
                                cancellation_rate_basis == null
                                  ? ""
                                  : cancellation_rate_basis
                                      .cancellation_rules[1] == null
                                  ? ""
                                  : cancellation_rate_basis
                                      .cancellation_rules[1].to_date
                              }}
                              {{ $t("by_cost") }}
                              {{
                                cancellation_rate_basis == null
                                  ? ""
                                  : cancellation_rate_basis
                                      .cancellation_rules[1] == null
                                  ? ""
                                  : cancellation_rate_basis
                                      .cancellation_rules[1].cancel_charge
                              }}</span
                            >
                            <span
                              >{{ $t("amend_from") }}
                              {{
                                cancellation_rate_basis == null
                                  ? ""
                                  : cancellation_rate_basis
                                      .cancellation_rules[1] == null
                                  ? ""
                                  : cancellation_rate_basis
                                      .cancellation_rules[1].from_date
                              }}
                              {{ $t("to") }}
                              {{
                                cancellation_rate_basis == null
                                  ? ""
                                  : cancellation_rate_basis
                                      .cancellation_rules[1] == null
                                  ? ""
                                  : cancellation_rate_basis
                                      .cancellation_rules[1].to_date
                              }}
                              {{ $t("by_cost") }}
                              {{
                                cancellation_rate_basis == null
                                  ? ""
                                  : cancellation_rate_basis
                                      .cancellation_rules[1] == null
                                  ? ""
                                  : cancellation_rate_basis
                                      .cancellation_rules[1].amend_charge
                              }}</span
                            >
                          </div>
                        </div>
                        <a href="#" class="close-popup"></a>
                      </div>
                    </div>
                    <div class="room_price">
                      <div
                        class="d-flex align-items-center justify-content-center flex-column h-100"
                      >
                        <p class="price mb-0">
                          {{ convertedPrice(roomPrice.selling_price) }}
                          {{ coinCode }} <span> / {{ $t("nights") }}</span>
                        </p>
                        <span>{{ $t("with_tax_vat") }}</span>
                      </div>
                    </div>
                    <div
                      class="counter"
                      :class="{ disabled: no_of_rooms == 1 }"
                    >
                      <div class="add_room" @click="add_room(roomPrice, room)">
                        <i class="fa-solid fa-plus"></i>
                      </div>
                      <div class="count">
                        {{
                          no_of_rooms == 1
                            ? roomPrice.room_count
                            : roomPrice.count
                        }}
                      </div>
                      <div
                        class="remove_room"
                        @click="minus_room(roomPrice, room)"
                      >
                        <i class="fa-solid fa-minus"></i>
                      </div>
                    </div>
                    <div class="card_text" v-if="no_of_rooms == 1">
                      <div
                        class="d-flex align-items-center justify-content-center flex-column h-100"
                      >
                        <p class="total_night">
                          {{ $t("total_night") }} {{ numberOfNights }}
                          {{ $t("night") }}
                        </p>
                        <p class="total_price">{{room.is_contract == 1 ? roomPrice.total_selling_price : convertedPrice(roomPrice.selling_price * numberOfNights)}}
                          {{ coinCode }} <span>{{ $t("with_tax_vat") }}</span>
                        </p>
                        <div class="booking">
                          <button
                            :disabled="no_of_rooms > 1"
                            class="btn btn-booking"
                            type="button"
                            @click="
                              checkRoomCancellationPolicies(
                                index,
                                'book',
                                room,
                                indexRoomPrice
                              )
                            "
                          >
                            {{ $t("Book now") }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <!-- <img src="@/assets/media/image/" alt="no rooms available"> -->
          <h6 class="text-center">{{ $t("no_rooms_available") }}</h6>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Loading from "@/components/LottieFiles/LoadingComponent.vue";
import Swal from "sweetalert2";

export default {
  name: "room-option",
  components: { Loading },
  props: {
    hotelData: Object
  },
  data() {
    return {
      hotel_code: this.$route.params.id,
      roomsData: [],
      hotel_rooms: [],
      hoverData: [],
      loading: false,
      numberOfNights: 0,
      check_in_date: JSON.parse(localStorage.getItem("hotelData"))?.check_in,
      check_out_date: JSON.parse(localStorage.getItem("hotelData"))?.check_out,
      coinCode:
        localStorage.getItem("coinCode") == null
          ? "USD"
          : localStorage.getItem("coinCode"),
      cancellation_rate_basis: null,
      no_of_rooms: localStorage.getItem("hotelDataSearch") ? JSON.parse(localStorage.getItem("hotelDataSearch"))
        ?.no_of_rooms : 1,
      // no_of_rooms: 2,
      total_rooms_count: 0,
      checked_rooms: [],
      roomPrices: localStorage.getItem("hotel_prices") ? JSON.parse(localStorage.getItem("hotel_prices")) : [],
      DefaultImage: localStorage.getItem("DefaultImage"),
      route: this.$route,
    };
  },
  methods: {
    calculateNumberOfNights() {
      const checkInDate = new Date(this.check_in_date);
      const checkOutDate = new Date(this.check_out_date);

      // Calculate the time difference in milliseconds
      const timeDiff = checkOutDate.getTime() - checkInDate.getTime();

      // Calculate the number of nights
      const nights = Math.ceil(timeDiff / (1000 * 3600 * 24));

      // Update the numberOfNights data property
      this.numberOfNights = nights ? nights : 1;

      console.log("nights", nights);
    },
    add_room(roomPrice, room) {
      // console.log(roomPrice);
      // console.log(room);
      if (this.total_rooms_count < this.no_of_rooms) {
        roomPrice.count = roomPrice.count + 1;
        this.total_rooms_count = this.total_rooms_count + 1;

        // Parent room
        room.checked = true;

        // Child room
        roomPrice.checked = true;

        // console.log(room);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `Maximum rooms is ${this.no_of_rooms}`,
          timer: 3000,
        });
      }
    },
    minus_room(roomPrice, room) {
      if (roomPrice.count > 0) {
        roomPrice.count = roomPrice.count - 1;
        this.total_rooms_count = this.total_rooms_count - 1;
        roomPrice.checked = false;

        let checkedRoomPrice = 0;

        room.room_rate_basis.forEach((ele) => {
          checkedRoomPrice = checkedRoomPrice + ele.checked;
        });

        // console.log(checkedRoomPrice);
        if (!checkedRoomPrice) {
          room.checked = false;
        }

        // console.log(room);
      }
    },
    organizeRoomsData() {
      this.checked_rooms = this.roomsData.filter((ele) => {
        return ele.checked == true;
      });

      this.checked_rooms.forEach((ele) => {
        ele.room_rate_basis = ele.room_rate_basis.filter((room) => {
          return room.checked == true;
        });
      });

      // console.log(this.checked_rooms);
      // console.log(this.roomsData, "wwwwww");

      this.bookMultipleRooms();
    },
    bookMultipleRooms() {
      const hotelData = JSON.parse(localStorage.getItem("hotelData"));

      let multiRoomObj = {
        check_in: hotelData.check_in,
        check_out: hotelData.check_out,
        hotel_id: this.hotel_code,
        hotel_name: hotelData.hotel_name,
        nationality_code: hotelData.nationality_code,
        passenger_country_of_residence_code:
          hotelData.passenger_country_of_residence_code,
        rooms: [],
        meta_data: {
          credential_id: 2,
          provider_id: 2,
        },
      };
      this.checked_rooms.forEach((ele) => {
        multiRoomObj.rooms = ele.room_rate_basis.map((room) => {
          return {
            room_type_name: ele.name,
            room_price: room.price,
            description: room.description,
            room_number: ele.id,
            rate_basis: room.id,
            allocation_details: room.allocationDetails,
            meta_data: {
              room_index: ele.meta_data.room_index,
              room_type_code: ele.meta_data.room_type_code,
              room_type_name: ele.meta_data.room_type_name,
              rate_plan_code: ele.meta_data.rate_plan_code,
              supplements: ele.meta_data.supplements,
              room_rate: {
                room_fare:
                  ele.meta_data.room_rate == null
                    ? null
                    : ele.meta_data.room_rate.original_room_fare,
                total_fare:
                  ele.meta_data.room_rate == null
                    ? null
                    : ele.meta_data.room_rate.original_total_fare,
                room_tax:
                  ele.meta_data.room_rate == null
                    ? null
                    : ele.meta_data.room_rate.room_tax,
                currency:
                  ele.meta_data.room_rate == null
                    ? null
                    : ele.meta_data.room_rate.currency,
                agent_mark_up: ele.meta_data.room_rate == null ? null : 0,
              },
            },
            room_guests: {
              adults: [],
              children: [],
              extrabeds: [],
            },
          };
        });
      });

      multiRoomObj.rooms.forEach((ele) => {
        // Iterate over the adults array
        for (
          let i = 0;
          i <
          JSON.parse(localStorage.getItem("hotelData")).rooms_guests[0]
            .adults_count;
          i++
        ) {
          ele.room_guests.adults.push({
            salutation: "",
            first_name: "",
            last_name: "",
            age: 30, // You can set the age to the desired value
          });
        }
        for (
          let i = 0;
          i <
          JSON.parse(localStorage.getItem("hotelData")).rooms_guests[0]
            .children_count;
          i++
        ) {
          ele.room_guests.children.push({
            salutation: "",
            first_name: "",
            last_name: "",
            age: 7, // You can set the age to the desired value
          });
        }
      });

      // console.log(multiRoomObj);
      localStorage.setItem("roomData", JSON.stringify(multiRoomObj));
      this.$router.push("/hotel/bookingHotel");
    },

    handleAvailableRoomParams(priceModel, roomPrices) {
      // Get the current date
      const today = new Date();

      // Calculate the check-in date (tomorrow)
      const checkInDate = new Date(today);
      checkInDate.setDate(today.getDate() + 1);

      // Calculate the check-out date (day after tomorrow)
      const checkOutDate = new Date(today);
      checkOutDate.setDate(today.getDate() + 2);

      // Format the dates as YYYY-MM-DD
      const checkInDateString = checkInDate.toISOString().split('T')[0];
      const checkOutDateString = checkOutDate.toISOString().split('T')[0];

      const hotelData = JSON.parse(localStorage.getItem("hotelData"));
      let filter = {
        hotel_id: this.hotel_code,
        nationality_code: hotelData ? hotelData.nationality_code : "SA",
        passenger_country_of_residence_code: hotelData ? hotelData.passenger_country_of_residence_code : "SA",
        rooms_guests: hotelData ? hotelData.rooms_guests : [{
          adults_count: 1,
          children_count: 0,
          children_ages: [],
          rate_basis: 1
        }],
        check_in: hotelData ? hotelData.check_in : checkInDateString,
        check_out: hotelData ? hotelData.check_out : checkOutDateString,
        converter_status: 1,
      };
      // console.log("priceModel", priceModel)
      if (priceModel.is_contract == 1) {
        filter.meta_data = {
          is_contract: 1,
          rate_code_id: roomPrices ? roomPrices : priceModel.rate_code_id,
          provider_id: 2,
          credential_id: 2,
        };
      } else {
        filter.meta_data = {
          provider_id: priceModel.provider_id,
          credential_id: priceModel.credential_id,
          is_contract: 0,
        };
      }

      return filter;
    },

    fetchProviderAvailableRooms(priceModel, roomPrices) {
      // console.log("priceModel", priceModel)
      // console.log('priceModel.price : ' + priceModel.min_price)
      // console.log('priceModel.rooms : ' + priceModel.rooms)
      // console.log('priceModel.indexxxxxxxx : ' + priceModel.index)
      if (priceModel.rooms != null) {
        this.roomsData = [];
        this.roomsData = [...priceModel.rooms];
      } else {
        this.loading = true;
        axios
          .post(
            "/fetch_new_available_rooms",
            this.handleAvailableRoomParams(priceModel, roomPrices)
          )
          .then(({ data }) => {
            this.roomsData = data?.data?.data ?? [];
            this.roomsData = data?.data?.data.map((ele) => {
              return {
                ...ele,
                checked: false,
              };
            });
            this.roomsData?.forEach((ele) => {
              ele.room_rate_basis = ele?.room_rate_basis.map((room) => {
                return {
                  ...room,
                  count: 0,
                  checked: false,
                };
              });
            });
            this.lengthOffers = true;
            this.loading = false;
          })
          .catch((error) => {
            Swal.fire({
              position: "center",
              icon: "error",
              title: error?.response?.data?.message,
              timer: 3000,
            });
            this.loading = false; //the loading Eneded
            return error;
          })
          .finally(() => {
            //Perform action in always
            this.loading = false; //the loading Eneded
            // console.log('priceModel.index : ' + priceModel.index)
            if (priceModel.index != null) {
              let priceIndex = this.roomPrices.findIndex(
                (e) => e.index == priceModel.index
              );
              if (priceIndex > -1) {
                this.roomPrices[priceIndex].rooms = [...this.roomsData];
              }
            }
          });
      }
    },

    book_now(room_parameter) {
      this.loading = true; //the loading started
      let hotelData = JSON.parse(localStorage.getItem("hotelData"));
      let session_id = hotelData.session_id;
      let result_index = hotelData.result_index;
      let response_time = 0; // ------------
      let hotel_code = this.hotel_code;
      let room = {
        room_id: room_parameter.roomNumber,
        room_count: room_parameter.count,
        room_fare: room_parameter.meta_data.room_rate.room_fare,
        room_tax: room_parameter.meta_data.room_rate.room_tax,
        total_fare: room_parameter.meta_data.room_rate.total_fare,
      };
      let hotelMeta = {
        session_id,
        result_index,
        response_time,
        hotel_code,
        rooms: [room],
      };

      axios
        .post("/check_home_availability_and_price", hotelMeta)
        .then(({ data }) => {
          // console.log(data)
          if (
            data.data.is_available_for_confirm_book == true &&
            data.data.is_available_for_book == true
          ) {
            hotelMeta = {
              session_id,
              result_index,
              response_time,
              hotel_code,
              rooms: [
                {
                  room_id: room_parameter.roomNumber,
                  room_count: room_parameter.count,
                  roomType: room_parameter.roomType,
                  room_metaData: room_parameter.meta_data,
                },
              ],
            };
            localStorage.setItem("hotelMeta", JSON.stringify(hotelMeta));
            localStorage.setItem("roomPrice", JSON.stringify(data.data));
            this.loading = false; // The loading ended
            this.$router.push("/hotel/bookingHotel");
          } else {
            this.loading = false; //the loading ended
            Swal.fire({
              position: "center",
              icon: "error",
              title: this.$t("room_not_available"),
              timer: 3000,
            });
          }
        });
    },
    async isHover(index, type, room) {
      this.load = true;
      const hotelData = JSON.parse(localStorage.getItem("hotelData"));
      let roomObject = {
        hotel_id: this.hotel_code,
        hotel_name: hotelData.hotel_name,
        room_numbers: [room.id],
        nationality_code: hotelData.nationality_code,
        passenger_country_of_residence_code: hotelData.nationality_code,
        rooms_guests: [
          {
            rate_basis: room.room_rate_basis[index].id,
            adults_count: 1,
            children_count: 0,
            extrabeds: [],
            children_ages: [],
          },
        ],
        check_in: hotelData.check_in,
        check_out: hotelData.check_out,
        meta_data: {
          credential_id: 2,
          provider_id: 2,
        },
        converter_status: 1,
      };
      if (this.resultIndex != null) {
        roomObject.meta_data.result_index = this.resultIndex;
        roomObject.meta_data.session_id = this.session_id;
      }
      if (this.resultIndex == null) {
        roomObject.converter_status = 1;
      }

      // Initialize hotel_rooms array if it doesn't exist
      if (!localStorage.getItem("roomData")) {
        localStorage.setItem("roomData", JSON.stringify([]));
      }
      // // Get the current bookData array from localStorage
      this.roomsSelected = roomObject;

      // Update the bookData array in localStorage
      localStorage.setItem("roomData", JSON.stringify(roomObject));

      let roomData = {
        check_in: hotelData.check_in,
        check_out: hotelData.check_out,
        hotel_id: hotelData.hotel,
        hotel_name: hotelData.hotel_name,
        nationality_code: hotelData.nationality_code,
        passenger_country_of_residence_code: hotelData.nationality_code,
        rooms: [
          {
            room_type_name: room.meta_data.room_type_name,
            room_number: room.id,
            rate_basis: room.room_rate_basis[index].id,
            room_price: room.room_rate_basis[index].price,
            description: room.room_rate_basis[index].description,
            allocation_details: room.room_rate_basis[index].allocationDetails,
            meta_data: {
              room_index: null,
              room_type_code: null,
              room_type_name: room.meta_data.room_type_name,
              rate_plan_code: null,
              supplements: [],
              room_rate: {
                room_fare: null,
                total_fare: null,
                room_tax: null,
                currency: null,
                agent_mark_up: null,
              },
            },
            room_guests: {
              adults: [],
              children: [],
              extrabeds: [],
            },
            room_rate_basis: room.room_rate_basis[index],
          },
        ],
        meta_data: {
          credential_id: 2,
          provider_id: 2,
        },
      };

      // Iterate over the adults array
      for (
        let i = 0;
        i <
        JSON.parse(localStorage.getItem("hotelData")).rooms_guests[0]
          .adults_count;
        i++
      ) {
        roomData.rooms[0].room_guests.adults.push({
          salutation: "",
          first_name: "",
          last_name: "",
          age: 30, // You can set the age to the desired value
        });
      }
      for (
        let i = 0;
        i <
        JSON.parse(localStorage.getItem("hotelData")).rooms_guests[0]
          .children_count;
        i++
      ) {
        roomData.rooms[0].room_guests.children.push({
          salutation: "",
          first_name: "",
          last_name: "",
          age: 7, // You can set the age to the desired value
        });
      }

      localStorage.setItem("roomData", JSON.stringify(roomData));

      // // Assign the array of adult objects to roomData.room_guests.adults
      // roomData.room_guests.adults = adultObjects;

      this.$emit("rooms-selected", this.roomsSelected);
      this.$emit("hotel-selected", this.hotel_id);

      if (!this.hoverData) {
        this.hoverData = [];
      }

      await axios
        .post("/check_rooms_availability", roomObject)
        .then(({ data }) => {
          // this.available_for_booking =
          //   data.data.data[0].cancellation_rate_basis[
          //     index
          //   ].available_for_booking;
          // this.cancellation_rate_basis =
          //   data.data.data[0].cancellation_rate_basis[index];
          this.hoverData = data.data;
          // console.log(this.hoverData);
        });
    },
    checkRoomCancellationPolicies(indexRateBasis, type, room, indexRoomPrice) {
      // console.log(indexRoomPrice)
      // Get the current date
      const today = new Date();

      // Calculate the check-in date (tomorrow)
      const checkInDate = new Date(today);
      checkInDate.setDate(today.getDate() + 1);

      // Calculate the check-out date (day after tomorrow)
      const checkOutDate = new Date(today);
      checkOutDate.setDate(today.getDate() + 2);

      // Format the dates as YYYY-MM-DD
      const checkInDateString = checkInDate.toISOString().split('T')[0];
      const checkOutDateString = checkOutDate.toISOString().split('T')[0];

      this.loading = true; //the loading begin
      const hotelData = JSON.parse(localStorage.getItem("hotelData"));
      let roomObject = {
        hotel_id: this.hotel_code,
        hotel_name: hotelData ? hotelData.hotel_name : this.hotelData.title,
        room_numbers: [room.id],
        nationality_code: hotelData ? hotelData.nationality_code : "SA",
        passenger_country_of_residence_code: hotelData ? hotelData.nationality_code : "SA",
        rooms_guests: [
          {
            rate_basis: hotelData ? room.room_rate_basis[indexRateBasis].id : "1",
            adults_count: hotelData ? hotelData.rooms_guests[0].adults_count : 1,
            children_count: hotelData ? hotelData.rooms_guests[0].children_count : 0,
            extrabeds: [],
            children_ages: hotelData ? hotelData.rooms_guests[0].children_ages : [],
          },
        ],
        check_in: hotelData ? hotelData.check_in : checkInDateString,
        check_out: hotelData ? hotelData.check_out : checkOutDateString,
        meta_data: {
          credential_id: 2,
          provider_id: 2,
        },
        converter_status: 1,
      };

      if (this.resultIndex != null) {
        roomObject.meta_data.result_index = this.resultIndex;
        roomObject.meta_data.session_id = this.session_id;
      }
      if (this.resultIndex == null) {
        roomObject.converter_status = 1;
      }

      // Initialize hotel_rooms array if it doesn't exist
      if (!localStorage.getItem("roomData")) {
        localStorage.setItem("roomData", JSON.stringify([]));
      }
      // // Get the current bookData array from localStorage
      this.roomsSelected = roomObject;

      // Update the bookData array in localStorage
      localStorage.setItem("roomData", JSON.stringify(roomObject));

      // console.log(room)

      let roomData = {
        check_in: hotelData ? hotelData.check_in : checkInDateString,
        check_out: hotelData ? hotelData.check_out : checkOutDateString,
        hotel_id: this.hotel_code,
        hotel_name: hotelData ? hotelData.hotel_name : this.hotelData.title,
        nationality_code: hotelData ? hotelData.nationality_code : "SA",
        passenger_country_of_residence_code: hotelData ? hotelData.nationality_code : "SA",
        rooms: [],
        meta_data: {
          credential_id: 2,
          provider_id: 2,
          is_contract: room.is_contract,
          rate_code_id: room.is_contract == 0 ? undefined : this.roomPrices[indexRoomPrice]?.rate_code_id,
        },
      };

      // Iterate over the rooms_guests array
      // const room_guests = [
      //   {
      //     "rate_basis": "1",
      //     "adults_count": 1,
      //     "children_count": 0,
      //     "children_ages": []
      //   }
      // ];
      const hotelDataSearch = JSON.parse(localStorage.getItem("hotelDataSearch"));
      // roomIndex < hotelDataSearch ? hotelDataSearch.rooms_guests.length : room_guests.length;
      for (
        let roomIndex = 0;
        roomIndex < hotelDataSearch.rooms_guests.length;
        roomIndex++
      ) {
        const roomGuestsData = hotelDataSearch.rooms_guests[roomIndex];
        // console.log("test", hotelDataSearch.rooms_guests[roomIndex]);
        // console.log("roomGuestsData", roomGuestsData);
        // Create room object
        const roomObjectData = {
          room_type_name: room.meta_data.room_type_name,
          room_number: room.id,
          rate_basis: room.room_rate_basis[indexRateBasis].id,
          room_price: room.room_rate_basis[indexRateBasis].price,
          description: room.room_rate_basis[indexRateBasis].description,
          allocation_details:
            room.room_rate_basis[indexRateBasis].allocationDetails,
          meta_data: {
            room_index: null,
            room_type_code: null,
            room_type_name: room.meta_data.room_type_name,
            rate_plan_code: null,
            supplements: [],
            room_rate: {
              room_fare: null,
              total_fare: null,
              room_tax: null,
              currency: null,
              agent_mark_up: null,
            },
          },
          room_rate_basis: room.room_rate_basis[indexRateBasis],
          room_guests: {
            adults: [],
            children: [],
            extrabeds: [],
          },
        };

        // Add adult guests to the room
        for (
          let adultIndex = 0;
          adultIndex < roomGuestsData.adults_count;
          adultIndex++
        ) {
          roomObjectData.room_guests.adults.push({
            salutation: "",
            first_name: "",
            last_name: "",
            age: 30, // You can set the age to the desired value
          });
        }

        // Add children guests to the room
        for (
          let childIndex = 0;
          childIndex < roomGuestsData.children_count;
          childIndex++
        ) {
          roomObjectData.room_guests.children.push({
            salutation: "",
            first_name: "",
            last_name: "",
            age: roomGuestsData.children_ages[childIndex], // Set the age to the desired value
          });
        }

        // Add the room object to the rooms array in roomData
        roomData.rooms.push(roomObjectData);
      }

      localStorage.setItem("roomData", JSON.stringify(roomData));

      // // Assign the array of adult objects to roomData.room_guests.adults
      // roomData.room_guests.adults = adultObjects;

      this.$emit("rooms-selected", this.roomsSelected);
      this.$emit("hotel-selected", this.hotel_id);

      this.loading = true;
      this.$router.push("/hotel/bookingHotel");
      if (room.is_contract == 0) {
        axios
          .post("/check_rooms_availability", roomObject)
          .then(({ data }) => {
            this.available_for_booking =
              data.data.data[0].cancellation_rate_basis[
                indexRateBasis
              ].available_for_booking;
            this.cancellation_rate_basis =
              data.data.data[0].cancellation_rate_basis[indexRateBasis];
            // console.log(this.cancellation_rate_basis)
            // console.log(data.data)
            if (this.available_for_booking == true) {
              this.loading = false; //the loading ended
            } else {
              this.loading = false; //the loading ended
              Swal.fire({
                title: "",
                text: this.$t("room is not available"),
                icon: "error",
              });
            }
            if (type == "book") {
              localStorage.setItem(
                "cancellation_policy",
                JSON.stringify(this.cancellation_rate_basis)
              );
              this.$router.push("/hotel/bookingHotel");
            }
          })
          .catch((error) => {
            Swal.fire({
              position: "center",
              icon: "error",
              title: error?.response?.data?.message,
              timer: 3000,
            });
            this.loading = false; //the loading Eneded
            return error;
          })
          .finally(() => {
            this.loading = false; //the loading Eneded
          });
      }
    },
    fetchContractRateCode() {
      // Get the current date
      const today = new Date();

      // Calculate the check-in date (tomorrow)
      const checkInDate = new Date(today);
      checkInDate.setDate(today.getDate() + 1);

      // Calculate the check-out date (day after tomorrow)
      const checkOutDate = new Date(today);
      checkOutDate.setDate(today.getDate() + 2);

      // Format the dates as YYYY-MM-DD
      const checkInDateString = checkInDate.toISOString().split('T')[0];
      const checkOutDateString = checkOutDate.toISOString().split('T')[0];

      const formData = new FormData();
      formData.append("hotel_code", this.hotel_code);
      formData.append("check_in", checkInDateString);
      formData.append("check_out", checkOutDateString);
      axios
        .post("/fetch_contract_from_hotels", formData)
        .then(({ data }) => {
          this.roomPrices = data.data;
          const roomPricesLength = data.data;
          this.loading = false; //the loading Eneded


          const priceModel = {
            is_contract: 1
          }
          if(roomPricesLength.length) {
            this.fetchProviderAvailableRooms(priceModel, this.roomPrices[0]?.rate_code_id);
          }
        })
        .catch((error) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: error?.response?.data?.message,
            timer: 3000,
          });
          this.loading = false; //the loading Eneded
          return error;
        })
        .finally(() => {});
    }
  },
  mounted() {
    // console.log(this.rooms)
    // console.log(this.no_of_rooms);
    this.calculateNumberOfNights();
    // console.log("routeParams", this.$route.params.page);
    if (this.$route.params.page == 0 && this.roomPrices.length) {
      // console.log(this.roomPrices);
      this.fetchProviderAvailableRooms(this.roomPrices[0]);
    }
    else if (this.$route.params.page == 1){
      this.fetchContractRateCode();
    }
  },
  created() {
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "./_roomsOption.scss";
</style> -->
