import { createApp } from "vue";
import App from "./App.vue";
import { createI18n } from "vue-i18n";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import rate from "vue-rate";
import "vue-rate/dist/vue-rate.css";
import router from "../src/router/router.js";
import store from "./store/index.js";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import FlagIcon from "vue-flag-icon";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import VueSocialChat from "vue-social-chat";
import "vue-social-chat/dist/style.css";
import PrimeVue from "primevue/config";
import { globalMixin } from "@/mixins";
import { createPinia } from "pinia";
import setupAxios from "./axios/axiosConfig"; // Import the axios configuration function
import ToastService from "primevue/toastservice";

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const globalOptions = {
  mode: "auto",
};

let lang = "ar";
if (localStorage.getItem("lang")) {
  lang = localStorage.getItem("lang");
}

const i18n = createI18n({
  locale: lang, // set locale
  // allowComposition: true, // you need to specify that!
  fallbackLocale: lang, // set fallback locale
  messages: loadLocaleMessages(), // set locale messages
});

const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);

const newLocal = lang;
axios.defaults.headers.common["Accept-Language"] = newLocal;

// Call the axios configuration function
setupAxios();

app.config.globalProperties.axios = axios; // Use the axios instance from the separate file
app.use(i18n);
app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyC1hpAsZWGRTOGSzsyfZT4rGH3iVlEPrUM",
  },
});

app.use(PrimeVue);
app.use(VueTelInput, globalOptions); // Define default global options here (optional)
app.use(FlagIcon);
app.use(createPinia());
app.use(rate);
app.use(VueSocialChat);
app.use(router);
app.use(store);
app.use(ToastService);
app.mount("#app");

// Mixins -----------------------------------------------
app.mixin(globalMixin);
