<template>
  <section class="login">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-7 col-12">
          <div class="container">
            <div class="title">
              <h2>
                {{ $t("Welcome to you in website")
                }}<span class="orange"></span> <span class="green"></span>
              </h2>
              <p>
                {{ $t("Please enter the following information to continue") }}
              </p>
            </div>
            <form @submit.prevent="login">
              <div class="form-group">
                <label class="form-label" for="email">{{ $t("email") }}</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  v-model="email"
                  :placeholder="$t('enter email')"
                />
              </div>
              <div class="form-group passord">
                <label class="form-label" for="password">{{
                  $t("password")
                }}</label>
                <input
                  v-if="showPassword"
                  type="text"
                  class="form-control"
                  id="password"
                  v-model="password"
                  :placeholder="$t('enter password')"
                />
                <input
                  v-else
                  type="password"
                  class="form-control"
                  id="password"
                  v-model="password"
                  :placeholder="$t('enter password')"
                />
                <i
                  class="fa-solid"
                  :class="{
                    'fa-eye-slash': !showPassword,
                    'fa-eye': showPassword,
                  }"
                  @click="toggleShow"
                ></i>
              </div>
              <!-- <div class="form-group">
                            <input type="checkbox" id="remember" v-model="remember"/>
                            <label for="remember" class="mx-2">{{$t("Remember me")}}</label>
                        </div> -->
              <button class="btn PrimaryButton w-100" type="submit">
                {{ $t("login") }}
              </button>
              <div
                class="d-flex align-items-center justify-content-between mt-3"
              >
                <router-link to="/signUp"
                  >{{ $t("not have account ?") }}
                  <span>{{ $t("create account") }}</span></router-link
                >
                <resetPaword />
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-5 col-12">
          <div class="image">
            <img :src="login_image" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import resetPaword from "@/components/resetPaswword/resetPawword.vue";
export default {
  data() {
    return {
      email: "",
      password: "",
      remember: false,
      showPassword: false,
      login_image: null,
    };
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    async login() {
      const formData = new FormData();
      formData.append("email", this.email);
      formData.append("password", this.password);
      let response = await this.$store.dispatch("Login", formData);
      // console.log(this.phone.replace(/\s+/g, ''));
      if (this.email === "" || this.password === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("Please dictate all fields"),
        });
      } else {
        try {
          if (response.data.status == true) {
            // console.log(response.data);
            // console.log("status" + response.data.status);
            Swal.fire({
              position: "center",
              icon: "success",
              title: response.data.message,
            });
            this.form = {
              password: "",
            };
            (this.phone = ""), this.$router.go("/");
            this.error = null;
          } else if (response.data.status == false) {
            // console.log(response.data);
            // console.log("status : " + response.data.status);
            Swal.fire({
              position: "center",
              icon: "error",
              title: response.data.message,
            });
          }
        } catch (err) {
          // console.log(err.response)
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.response.data.message,
          });
        }
      }
      // if (this.rememberMe) {
      //     Cookies.set('rememberedUser', this.phone, { expires: 7 });
      // }
    },
    webSiteHeaders() {
      axios
        .get(`/fetch_web_site_headers`)
        .then((res) => (this.login_image = res.data.data.login_image))
        .catch((err) => console.log(err));
      // console.log(searchText);
    },
  },
  created() {
    localStorage.removeItem("hotelData");
    localStorage.removeItem("hotelMeta");
    localStorage.removeItem("roomPrice");
    localStorage.removeItem("hotelDataSearch");
    this.webSiteHeaders();
  },
  components: {
    resetPaword,
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "./_login.scss";
</style> -->
