export const globalMixin = {
  methods: {
    maxWordTruncate(value, count = 15) {
      if (value && value.length > count) {
        return value && value.substring(0, count) + "...";
      } else {
        return value;
      }
    },
    // convertedPrice(price) {
    //     const selectedCoin = JSON.parse(localStorage.getItem("coinCodes")).find(coin => coin.code === this.coinCode);
    //     return (price * selectedCoin.price).toFixed(2);
    // },

    convertedPrice(price) {
      const coinCodes = JSON.parse(localStorage.getItem("coinCodes")) || [];
      const selectedCoin = coinCodes.find((coin) => coin.code === this.coinCode) ?? { price: 1, is_default: false, code: "USD" };

      // const selectedCoinPrice = parseFloat((selectedCoin.price || "1").toString().replace(",", "."));
      // console.log(selectedCoinPrice, "selectedCoin price");

      const isMasterCoin = coinCodes.find((coin) => coin.code == "USD") || { price: 1 };
      const isMasterPrice = parseFloat((isMasterCoin.price || "1").toString().replace(",", "."));
      // console.log(isMasterPrice, "isMasterPrice");

      const requiredPercent = parseFloat((selectedCoin.price || "1").toString().replace(",", "."));
      // console.log(requiredPercent, "requiredPercent");

      if (selectedCoin.is_default && selectedCoin.code != "USD") {
        // console.log("default");
        return (price * requiredPercent).toFixed(2);
      }
      if (selectedCoin.is_default && selectedCoin.code == "USD") {
        // console.log("master");
        return (price * requiredPercent).toFixed(2);
      } else {
        const calculatedPrice = price * ((1 / isMasterPrice) * requiredPercent);
        // console.log(calculatedPrice, "calculated price");
        return calculatedPrice.toFixed(2);
      }
    },


    convertedPriceFromSystem(price) {
      const coinCodes = JSON.parse(localStorage.getItem("coinCodes")) || [];
      const selectedCoin = coinCodes.find((coin) => coin.code === this.coinCode) ?? { price: 1, is_default: false, code: "USD" };

      // const selectedCoinPrice = parseFloat((selectedCoin.price || "1").toString().replace(",", "."));
      // console.log(selectedCoinPrice, "selectedCoin price");

      // const isMasterCoin = coinCodes.find((coin) => coin.code == "USD") || { price: 1 };
      const isDefaultCoin = coinCodes.find((coin) => coin.is_default == 1) || { price: 1 };
      // const isMasterPrice = parseFloat((isMasterCoin.price || "1").toString().replace(",", "."));
      // console.log(isMasterPrice, "isMasterPrice");

      const requiredPercent = parseFloat((selectedCoin.price || "1").toString().replace(",", "."));
      // console.log(requiredPercent, "requiredPercent");
      // console.log("code", selectedCoin.code);
      // console.log("is_default", selectedCoin.is_default);

      if (selectedCoin.is_default == 1 && selectedCoin.code != "USD") {
        // console.log("test")
        return (price);
      }
      else if (selectedCoin.is_default == 1 && selectedCoin.code == "USD") {
        // console.log("master");
        return parseFloat((price * requiredPercent).toFixed(2));
      } 
      // else if(selectedCoin.is_default != 1 && selectedCoin.code == "USD"){
      //   return (price);
      // }
      else {
        const calculatedPrice = (price / isDefaultCoin.price) * selectedCoin.price;
        // const calculatedPrice = price * ((1 / isMasterPrice) * requiredPercent);
        // console.log(calculatedPrice, "calculated price");
        return parseFloat(calculatedPrice.toFixed(2));
      }
    }

  },
};
