<template>
  <section class="book_umrah">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <ul class="pages_tree">
            <li>
              <router-link to="/">{{ $t("home") }}</router-link>
              <i class="fa-solid fa-caret-left"></i>
            </li>
            <li>
              <router-link to="/umrah_package">{{
                $t("package_reservation")
              }}</router-link>
              <i class="fa-solid fa-caret-left"></i>
            </li>
            <li>
              {{ package_details.title }}
              <i class="fa-solid fa-caret-left"></i>
            </li>
            <li>{{ $t("payment") }}</li>
          </ul>
        </div>
        <div class="col-12 col-lg-8">
          <div class="booking_container">
            <div class="booking">
              <form class="pb-0" @submit.prevent="bookUmrah()">
                <div class="form-check form-switch d-flex justify-content-end">
                  <label for="toggle_form_data" class="text_design">
                    {{
                      toggle_form_data ? $t("another_persons") : $t("for_me")
                    }}
                  </label>
                  <input
                    class="form-check-input switch mx-1"
                    type="checkbox"
                    id="toggle_form_data"
                    v-model="toggle_form_data"
                  />
                </div>
                <div class="row" v-for="(adult, index) in adults" :key="index">
                  <div class="frist-head">
                    <h6 class="title">
                      {{ $t("Personal data") }} ({{ index + 1 }})
                    </h6>
                  </div>
                  <div class="col-xl-3 col-lg-4 col-md-12 col-12">
                    <label for="">{{ $t("Title") }}</label>
                    <div class="form-group gendersADT">
                      <Multiselect
                        v-model="adult.gender_value"
                        :options="genders.map((gender) => gender.id)"
                        :custom-label="
                          (opt) => genders.find((x) => x.id == opt).title
                        "
                        :clear-on-select="true"
                        select-label=""
                        :placeholder="$t('gender')"
                        :hide-selected="true"
                      >
                      </Multiselect>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-4 col-md-12 col-12">
                    <div
                      class="form-group"
                      v-if="!toggle_form_data && index === 0"
                    >
                      <label for="">{{ $t("first_name") }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="user.first_name"
                        :placeholder="$t('first_name')"
                      />
                    </div>
                    <div class="form-group" v-else>
                      <label for="">{{ $t("first_name") }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="adult.first_name"
                        :placeholder="$t('first_name')"
                      />
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-4 col-md-12 col-12">
                    <div
                      class="form-group"
                      v-if="!toggle_form_data && index === 0"
                    >
                      <label for="">{{ $t("last_name") }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="user.last_name"
                        :placeholder="$t('last_name')"
                      />
                    </div>
                    <div class="form-group" v-else>
                      <label for="">{{ $t("last_name") }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="adult.last_name"
                        :placeholder="$t('last_name')"
                      />
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-12 col-12">
                    <div class="form-group">
                      <label>{{ $t("nationality") }}</label>
                      <Multiselect
                        v-model="adult.nationality_value"
                        label="title"
                        track-by="id"
                        :options="nationalities"
                        :clear-on-select="true"
                        select-label=""
                        :placeholder="$t('nationality')"
                        :hide-selected="true"
                        @keyup="fetch_nationalities"
                      >
                      </Multiselect>
                    </div>
                  </div>

                  <div class="frist-head">
                    <h6 class="title">
                      {{ $t("birth_day") }} ({{ index + 1 }})
                    </h6>
                  </div>
                  <div class="col-lg-3 col-md-4 col-12">
                    <label>{{ $t("day") }}</label>
                    <div class="form-group datePicker">
                      <Multiselect
                        v-model="adult.day"
                        :options="days"
                        label="day"
                        track-by="id"
                        :clear-on-select="true"
                        :placeholder="$t('day')"
                        select-label=""
                        :hide-selected="true"
                      >
                      </Multiselect>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-12">
                    <label>{{ $t("month") }}</label>
                    <div class="form-group datePicker">
                      <Multiselect
                        v-model="adult.month"
                        :options="months"
                        label="month"
                        track-by="id"
                        :clear-on-select="true"
                        :placeholder="$t('month')"
                        select-label=""
                        :hide-selected="true"
                      >
                      </Multiselect>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-12">
                    <label>{{ $t("year") }}</label>
                    <div class="form-group datePicker">
                      <Multiselect
                        v-model="adult.year"
                        :options="years"
                        label="year"
                        track-by="year"
                        :clear-on-select="true"
                        :placeholder="$t('year')"
                        select-label=""
                        :hide-selected="true"
                      >
                      </Multiselect>
                    </div>
                  </div>

                  <div
                    class="col-lg-4 col-md-12 col-12"
                    v-if="!toggle_form_data && index === 0"
                  >
                    <label
                      >{{ $t("email address") }} ({{ $t("optional") }})</label
                    >
                    <input
                      type="email"
                      class="form-control"
                      :placeholder="$t('email')"
                      v-model="user.email"
                    />
                  </div>
                  <div class="col-lg-4 col-md-12 col-12" v-else>
                    <label
                      >{{ $t("email address") }} ({{ $t("optional") }})</label
                    >
                    <input
                      type="email"
                      class="form-control"
                      :placeholder="$t('email')"
                      v-model="adult.email"
                    />
                  </div>
                  <div
                    class="col-lg-4 col-md-12 col-12"
                    v-if="!toggle_form_data && index === 0"
                  >
                    <label>{{ $t("Mobile number") }}</label>
                    <div class="form-group">
                      <vue-tel-input
                        v-model="user.phone"
                        v-bind="bindProps"
                        mode="international"
                        required
                      ></vue-tel-input>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-12 col-12" v-else>
                    <label>{{ $t("Mobile number") }}</label>
                    <div class="form-group">
                      <vue-tel-input
                        v-model="adult.PhoneNumber"
                        v-bind="bindProps"
                        mode="international"
                        required
                      ></vue-tel-input>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-12 col-12">
                    <label>{{ $t("profile photo") }}</label>
                    <input
                      type="file"
                      class="form-control"
                      :placeholder="$t('profile photo')"
                      required
                      @change="onFileChange($event, 'profile_photo',index, adults)"
                    />
                  </div>
                  <div class="second-head">
                    <h6 class="title">
                      {{ $t("Identity data") }} ({{ index + 1 }})
                    </h6>
                  </div>
                  <div class="col-lg-4 col-md-12 col-12">
                    <label>{{ $t("identity_type") }}</label>
                    <Multiselect
                      v-model="adult.identity_type_value"
                      :options="identity_type"
                      label="title"
                      track-by="id"
                      :clear-on-select="true"
                      select-label=""
                      :placeholder="$t('choose')"
                      :hide-selected="true"
                    >
                    </Multiselect>
                  </div>
                  <div
                    class="col-lg-4 col-md-12 col-12"
                    v-if="adult.identity_type_value.id == 1"
                  >
                    <label>{{ $t("passport number") }}</label>
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('passport')"
                      v-model="adult.passport_number"
                    />
                  </div>
                  <div
                    class="col-lg-4 col-md-12 col-12"
                    v-if="adult.identity_type_value.id == 2"
                  >
                    <label>{{ $t("nationality_id") }}</label>
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('nationality_id')"
                      v-model="adult.passport_number"
                    />
                  </div>
                  <div class="col-lg-4 col-md-12 col-12">
                    <label>{{ $t("Country of Issue") }}</label>
                    <Multiselect
                      v-model="adult.country_code"
                      label="title"
                      track-by="id"
                      :options="countries"
                      :clear-on-select="true"
                      select-label=""
                      :placeholder="$t('countries')"
                      :hide-selected="true"
                      @keyup="fetchCountries"
                    >
                    </Multiselect>
                  </div>
                  <div class="col-xl-2 col-lg-3 col-md-4 col-12">
                    <label>{{ $t("Expiry date") }}</label>
                    <div class="form-group datePicker">
                      <Multiselect
                        v-model="adult.expir_day"
                        :options="days"
                        label="day"
                        track-by="id"
                        :clear-on-select="true"
                        :placeholder="$t('day')"
                        select-label=""
                        :hide-selected="true"
                      >
                      </Multiselect>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-4 col-12">
                    <label></label>
                    <div class="form-group datePicker">
                      <Multiselect
                        v-model="adult.expir_month"
                        :options="months"
                        label="month"
                        track-by="id"
                        :clear-on-select="true"
                        :placeholder="$t('month')"
                        select-label=""
                        :hide-selected="true"
                      >
                      </Multiselect>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-4 col-12">
                    <label></label>
                    <div class="form-group datePicker">
                      <Multiselect
                        v-model="adult.expir_year"
                        :options="yearsExpiryDate"
                        label="year"
                        track-by="year"
                        :clear-on-select="true"
                        :placeholder="$t('year')"
                        select-label=""
                        :hide-selected="true"
                      >
                      </Multiselect>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-12 col-12">
                    <label>{{ $t("Passport copy") }}</label>
                    <input
                      type="file"
                      class="form-control"
                      :placeholder="$t('Passport copy')"
                      required
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                      @change="onFileChange($event, 'passport_photo',index, adults)"
                    />
                  </div>
                  <div class="col-lg-3 col-md-12 col-12">
                    <label>{{ $t("ID photo") }}</label>
                    <input
                      type="file"
                      name=""
                      class="form-control"
                      :placeholder="$t('ID photo')"
                      required
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                      @change="onFileChange($event, 'id_photo',index, adults)"
                    />
                  </div>
                </div>

                <div
                  class="row"
                  v-for="(children, index) in childrens"
                  :key="index"
                >
                  <div class="head-child">
                    <h6 class="title">
                      {{ $t("guest") }} ({{ $t("children") }}) ({{ index + 1 }})
                    </h6>
                  </div>
                  <div class="col-lg-4 col-md-12 col-12">
                    <div class="form-group">
                      <label for="">{{ $t("first_name") }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="children.first_name"
                        :placeholder="$t('first_name')"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-12 col-12">
                    <div class="form-group">
                      <label for="">{{ $t("last_name") }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="children.last_name"
                        :placeholder="$t('last_name')"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-12 col-12">
                    <div class="form-group">
                      <label>{{ $t("nationality") }}</label>
                      <Multiselect
                        v-model="children.nationality_value"
                        :options="
                          nationalities.map((nationality) => nationality.id)
                        "
                        :custom-label="
                          (opt) => nationalities.find((x) => x.id == opt).title
                        "
                        :clear-on-select="true"
                        select-label=""
                        :placeholder="$t('nationality')"
                        :hide-selected="true"
                        @keyup="fetch_nationalities"
                      >
                      </Multiselect>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-12">
                    <label>{{ $t("birth_day") }}</label>
                    <div class="form-group datePicker">
                      <Multiselect
                        v-model="children.day"
                        :options="days"
                        label="day"
                        track-by="id"
                        :clear-on-select="true"
                        :placeholder="$t('day')"
                        select-label=""
                        :hide-selected="true"
                      >
                      </Multiselect>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-12">
                    <label></label>
                    <div class="form-group datePicker">
                      <Multiselect
                        v-model="children.month"
                        :options="months"
                        label="month"
                        track-by="id"
                        :clear-on-select="true"
                        :placeholder="$t('month')"
                        select-label=""
                        :hide-selected="true"
                      >
                      </Multiselect>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-12">
                    <label></label>
                    <div class="form-group datePicker">
                      <Multiselect
                        v-model="children.year"
                        :options="years"
                        label="year"
                        track-by="year"
                        :clear-on-select="true"
                        :placeholder="$t('year')"
                        select-label=""
                        :hide-selected="true"
                      >
                      </Multiselect>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-12 col-12">
                    <label>{{ $t("profile photo") }}</label>
                    <input
                      type="file"
                      class="form-control"
                      :placeholder="$t('profile photo')"
                      required
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                      @change="onFileChange($event, 'profile_photo',index, childrens)"
                    />
                  </div>
                  <div class="third-head">
                    <h6 class="title">
                      {{ $t("Identity data") }} ({{ $t("child") }}) ({{
                        index + 1
                      }})
                    </h6>
                  </div>

                  <div class="col-lg-4 col-md-12 col-12">
                    <label>{{ $t("identity_type") }}</label>
                    <Multiselect
                      v-model="children.identity_type_value"
                      :options="identity_type"
                      label="title"
                      track-by="id"
                      :clear-on-select="true"
                      select-label=""
                      :placeholder="$t('choose')"
                      :hide-selected="true"
                    >
                    </Multiselect>
                  </div>

                  <div
                    class="col-lg-4 col-md-12 col-12"
                    v-if="children.identity_type_value.id == 1"
                  >
                    <label>{{ $t("passport number") }}</label>
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('passport')"
                      v-model="children.passport_number"
                    />
                  </div>
                  <div
                    class="col-lg-4 col-md-12 col-12"
                    v-if="children.identity_type_value.id == 2"
                  >
                    <label>{{ $t("nationality_id") }}</label>
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('nationality_id')"
                      v-model="children.passport_number"
                    />
                  </div>
                  <div class="col-lg-4 col-md-12 col-12">
                    <label>{{ $t("Country of Issue") }}</label>
                    <Multiselect
                      v-model="children.country_code"
                      label="title"
                      track-by="id"
                      :options="countries"
                      :clear-on-select="true"
                      select-label=""
                      :placeholder="$t('countries')"
                      :hide-selected="true"
                      @keyup="fetchCountries"
                    >
                    </Multiselect>
                  </div>
                  <div class="col-xl-2 col-lg-3 col-md-4 col-12">
                    <label>{{ $t("Expiry date") }}</label>
                    <div class="form-group datePicker">
                      <Multiselect
                        v-model="children.expir_day"
                        :options="days"
                        label="day"
                        track-by="id"
                        :clear-on-select="true"
                        :placeholder="$t('day')"
                        select-label=""
                        :hide-selected="true"
                      >
                      </Multiselect>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-4 col-12">
                    <label></label>
                    <div class="form-group datePicker">
                      <Multiselect
                        v-model="children.expir_month"
                        :options="months"
                        label="month"
                        track-by="id"
                        :clear-on-select="true"
                        :placeholder="$t('month')"
                        select-label=""
                        :hide-selected="true"
                      >
                      </Multiselect>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-4 col-12">
                    <label></label>
                    <div class="form-group datePicker">
                      <Multiselect
                        v-model="children.expir_year"
                        :options="yearsExpiryDate"
                        label="year"
                        track-by="year"
                        :clear-on-select="true"
                        :placeholder="$t('year')"
                        select-label=""
                        :hide-selected="true"
                      >
                      </Multiselect>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-12 col-12">
                    <label>{{ $t("ID photo") }}</label>
                    <input
                      type="file"
                      class="form-control"
                      :placeholder="$t('profile photo')"
                      required
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                      @change="onFileChange($event, 'id_photo',index, childrens)"
                    />
                  </div>
                  <div class="col-lg-3 col-md-12 col-12">
                    <label>{{ $t("Passport copy") }}</label>
                    <input
                      type="file"
                      class="form-control"
                      :placeholder="$t('profile photo')"
                      required
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                      @change="onFileChange($event, 'passport_photo',index,childrens)"
                    />
                  </div>
                </div>
                <hr />

                <div class="row" v-for="(baby, index) in babies" :key="index">
                  <div class="head">
                    <h6 class="title">{{ $t("guest") }} ({{ $t("baby") }})</h6>
                  </div>
                  <div class="col-lg-6 col-md-12 col-12">
                    <div class="form-group">
                      <label for="">{{ $t("first_name") }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="baby.first_name"
                        :placeholder="$t('first_name')"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-12">
                    <div class="form-group">
                      <label for="">{{ $t("last_name") }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="baby.last_name"
                        :placeholder="$t('last_name')"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-12">
                    <div class="form-group">
                      <label>{{ $t("nationality") }}</label>
                      <Multiselect
                        v-model="baby.nationality_value"
                        :options="
                          nationalities.map((nationality) => nationality.id)
                        "
                        :custom-label="
                          (opt) => nationalities.find((x) => x.id == opt).title
                        "
                        :clear-on-select="true"
                        select-label=""
                        :placeholder="$t('gender')"
                        :hide-selected="true"
                        @keyup="fetch_nationalities"
                      >
                      </Multiselect>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-12">
                    <label>{{ $t("date_of_birth") }}</label>
                    <Datepicker
                      v-model="baby.date_of_birth"
                      :lang="lang"
                      :placeholder="$t('date')"
                    />
                  </div>
                  <div class="col-lg-6 col-md-12 col-12">
                    <label
                      >{{ $t("email address") }} ({{ $t("optional") }})</label
                    >
                    <input
                      type="email"
                      class="form-control"
                      :placeholder="$t('email')"
                      v-model="baby.email"
                    />
                  </div>
                  <div class="col-lg-6 col-md-12 col-12">
                    <label>{{ $t("passport number") }}</label>
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('passport')"
                      v-model="baby.passport_number"
                    />
                  </div>
                </div>
                <h4>{{ $t("payment_method") }}</h4>
                <!-- {{ toggle_form_data }} -->
                <div class="d-flex align-items-center checkboxs py-3">
                  <div class="flex align-items-center mx-2">
                    <RadioButton
                      v-model="type"
                      inputId="ingredient1"
                      name="cash"
                      value="1"
                    />
                    <label for="ingredient1" class="mb-0 mx-2 fw-bold">
                      {{ $t("cash") }}
                    </label>
                  </div>
                  <div class="flex align-items-center mx-2">
                    <RadioButton
                      v-model="type"
                      inputId="ingredient2"
                      name="Package"
                      value="2"
                    />
                    <label for="ingredient2" class="mb-0 mx-2 fw-bold">
                      {{ $t("online") }}
                    </label>
                  </div>
                </div>
                <button
                  :disabled="!isFormValid"
                  type="submit"
                  class="btn w-100 mb-2 PrimaryButton pay_button mt-3"
                >
                  {{ $t("book_now") }}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="hotel_container">
            <div class="hotel">
              <div class="hotel_name">
                <div class="image">
                  <img :src="package_details.image" alt="package-image" />
                </div>
                <div class="text">
                  <h5>{{ package_details.title }}</h5>
                  <!-- <p class="address">jhjhjhjhjhj</p> -->
                  <div class="rating_data d-flex w-100">
                    <div class="rete-number d-flex gap-1 w-100">
                      <span>
                        ( {{ package_details.star_count }} {{ $t("rate") }})
                      </span>
                      <rate :rates="package_details.star_count"></rate>
                    </div>
                  </div>
                </div>
              </div>
              <ul class="hotel_details">
                <li>
                  <div class="detail">
                    <i class="fa-solid fa-users"></i>
                    {{ $t("number of person") }} : {{ number_of_adults }}
                    {{ $t("adults") }} - {{ number_of_childrens }}
                    {{ $t("childrens") }}
                  </div>
                </li>
                <li>
                  <div class="detail">
                    <i class="fa-regular fa-calendar"></i>
                    {{ $t("date_trip") }} :
                  </div>
                  <div class="detail_value">
                    {{
                      package_details.from_date
                        ? package_details.from_date
                        : package_details.trip_date
                    }}
                  </div>
                </li>
                <!-- <li>
                  <div class="detail">
                    <i class="fa-regular fa-calendar"></i>
                    {{ $t("Departure Date") }} :
                  </div>
                  <div class="detail_value">{{ package_details.to_date }}</div>
                </li> -->
                <!-- <li>
                  <div class="detail">
                    <i class="fa-solid fa-bed"></i>
                    {{ $t("room") }} :
                  </div>
                  <div class="detail_value">jfghdfgbdfddggf</div>
                </li> -->
                <!-- <li>
                  <a href="#popup">
                    <div class="detail">
                      <i class="fa-solid fa-circle-exclamation"></i>
                    </div>
                    <div class="detail_value">
                      {{ $t("Cancellation policy details") }}
                    </div>
                  </a>
                  <div id="popup" class="popup">
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <h4 class="title py-0">
                        {{ $t("Cancellation Policies") }}
                      </h4>
                      <a href="#" class="close">&times;</a>
                    </div>
                    <h5 class="title">yhyjhjghhnn</h5>
               
                    <div>
                      <span>{{ $t("cancel_free_from") }} ggfgfgh</span>
                      <span>{{ $t("cancel_from") }} vnmnmbnmbn</span>
                      <span>{{ $t("amend_from") }} mmhjmhmnjh</span>
                    </div>
                    
                  </div>
                  <a href="#" class="close-popup"></a>
                </li> -->
              </ul>
              <hr />
              <div class="payment">
                <h5>{{ $t("payment details") }}</h5>
                <ul class="payment_details">
                  <li>
                    <span class="title">{{ $t("Total Cost for adults") }}</span>
                    <span class="value">
                      {{ convertedPrice(package_prices.total_adults_price) }}
                      {{ coinCode }}
                    </span>
                  </li>
                  <li>
                    <span class="title">{{
                      $t("Total Cost for children")
                    }}</span>
                    <span class="value">
                      {{ convertedPrice(package_prices.total_child_price) }}
                      {{ coinCode }}
                    </span>
                  </li>
                  <li>
                    <span class="title">{{ $t("total service cost") }}</span>
                    <span class="value">
                      {{ convertedPrice(package_prices.service_selling) }}
                      {{ coinCode }}
                    </span>
                  </li>
                  <li>
                    <span class="title">{{ $t("Total costs") }}</span>
                    <span class="value">
                      {{ convertedPrice(package_prices.total_selling) }}
                      {{ coinCode }}
                    </span>
                  </li>
                  <li>
                    <span class="title">{{ $t("Added tax") }}</span>
                    <span class="value">
                      {{ package_prices.tax_value }} %
                    </span>
                  </li>

                  <li class="total">
                    <span class="title">{{ $t("Total after tax") }}</span>
                    <span class="value">
                      {{
                        convertedPrice(package_prices.total_selling_after_tax)
                      }}
                      {{ coinCode }}
                    </span>
                  </li>
                  <li class="coupon_submit">
                    <span class="title">{{ $t("price_atfer_coupon") }}</span>
                    <span class="total_value">
                      {{ convertedPrice(price_after_coupon) }} {{ coinCode }}
                    </span>
                  </li>
                </ul>
                <div class="coupon_code">
                  <form @submit.prevent="check_coupon()">
                    <div class="form-group mb-3">
                      <label class="mb-2">{{ $t("coupons") }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="coupon_code"
                      />
                    </div>
                    <button
                      type="submit"
                      class="btn PrimaryButton"
                      :disabled="!coupon_code"
                    >
                      {{ $t("submit_coupon") }}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VueDatepickerUi from "vue-datepicker-ui";
import rate from "@/components/rate.vue";
import moment from "moment";
import Swal from "sweetalert2";
import RadioButton from "primevue/radiobutton";
import { mapActions } from "pinia";
import { offlineBook } from "../../store/offlineBook";

export default {
  data() {
    return {
      toggle_form_data: true,
      user: JSON.parse(localStorage.getItem("user")),
      image: null,
      userBirthDay: {
        id:
          moment(JSON.parse(localStorage.getItem("user"))?.birth_day).format(
            "DD"
          ) || null,
        day:
          moment(JSON.parse(localStorage.getItem("user"))?.birth_day).format(
            "DD"
          ) || null,
      },
      userBirthMonth: {
        id:
          moment(JSON.parse(localStorage.getItem("user"))?.birth_day).format(
            "MM"
          ) || null,
        number:
          moment(JSON.parse(localStorage.getItem("user"))?.birth_day).format(
            "MM"
          ) || null,
        month:
          moment(JSON.parse(localStorage.getItem("user"))?.birth_day).format(
            "MM"
          ) || null,
      },
      userBirthYear: {
        id:
          moment(JSON.parse(localStorage.getItem("user"))?.birth_day).format(
            "YYYY"
          ) || null,
        year:
          moment(JSON.parse(localStorage.getItem("user"))?.birth_day).format(
            "YYYY"
          ) || null,
      },
      lang:
        localStorage.getItem("lang") == "ar" ||
        localStorage.getItem("lang") == undefined
          ? "ar"
          : "en",
      type: "",
      fetchedPackageData: {},
      package_details: {},
      package_prices: {},
      adults: [],
      childrens: [],
      babies: [],
      bindProps: {
        mode: "international",
        defaultCountry: "EG",
        valid: true,
        inputOptions: {
          maxlength: 25,
          type: "tel",
          placeholder: this.$t("phone"),
        },
      },
      validCharactersOnly: true,
      genders: [
        {
          id: 1,
          title: this.$t("mr"),
          code: "mr",
        },
        {
          id: 2,
          title: this.$t("ms"),
          code: "ms",
        },
      ],
      nationalities: [],
      number_of_adults: 0,
      number_of_childrens: 0,
      coupon_submit: false,
      coupon_code: "",
      price_after_coupon: "",
      identity_type: [
        {
          id: 1,
          title: this.$t("passport"),
        },
        {
          id: 2,
          title: this.$t("nationlity_id"),
        },
      ],
      countries: [],
      coinCode:
        localStorage.getItem("coinCode") == null
          ? "USD"
          : localStorage.getItem("coinCode"),
      days: [
        { id: 1, day: "01" },
        { id: 2, day: "02" },
        { id: 3, day: "03" },
        { id: 4, day: "04" },
        { id: 5, day: "05" },
        { id: 6, day: "06" },
        { id: 7, day: "07" },
        { id: 8, day: "08" },
        { id: 9, day: "09" },
        { id: 10, day: "10" },
        { id: 11, day: "11" },
        { id: 12, day: "12" },
        { id: 13, day: "13" },
        { id: 14, day: "14" },
        { id: 15, day: "15" },
        { id: 16, day: "16" },
        { id: 17, day: "17" },
        { id: 18, day: "18" },
        { id: 19, day: "19" },
        { id: 20, day: "20" },
        { id: 21, day: "21" },
        { id: 22, day: "22" },
        { id: 23, day: "23" },
        { id: 24, day: "24" },
        { id: 25, day: "25" },
        { id: 26, day: "26" },
        { id: 27, day: "27" },
        { id: 28, day: "28" },
        { id: 29, day: "29" },
        { id: 30, day: "30" },
        { id: 31, day: "31" },
      ],
      months: [
        {
          id: 1,
          number: "01",
          month: "01" + " - " + this.$t("January"),
        },
        {
          id: 2,
          number: "02",
          month: "02" + " - " + this.$t("February"),
        },
        {
          id: 3,
          number: "03",
          month: "03" + " - " + this.$t("March"),
        },
        {
          id: 4,
          number: "04",
          month: "04" + " - " + this.$t("April"),
        },
        {
          id: 5,
          number: "05",
          month: "05" + " - " + this.$t("May"),
        },
        {
          id: 6,
          number: "06",
          month: "06" + " - " + this.$t("June"),
        },
        {
          id: 7,
          number: "07",
          month: "07" + " - " + this.$t("July"),
        },
        {
          id: 8,
          number: "08",
          month: "08" + " - " + this.$t("August"),
        },
        {
          id: 9,
          number: "09",
          month: "09" + " - " + this.$t("September"),
        },
        {
          id: 10,
          number: "10",
          month: "10" + " - " + this.$t("October"),
        },
        {
          id: 11,
          number: "11",
          month: "11" + " - " + this.$t("November"),
        },
        {
          id: 12,
          number: "12",
          month: "12" + " - " + this.$t("December"),
        },
      ],
    };
  },
  components: {
    Multiselect,
    Datepicker: VueDatepickerUi,
    RadioButton,
    rate,
  },
  // computed: {

  // },
  methods: {
    ...mapActions(offlineBook, ["bookUmrahOffline"]),
    fetch_nationalities(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then(({ data }) => {
          this.nationalities = data.data.data;
          // console.log(this.nationalities);
        })
        .catch((err) => console.log(err));
    },
    fetchCountries(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then(({ data }) => {
          this.countries = data.data.data;
          // console.log(this.countries);
        })
        .catch((err) => console.log(err));
    },
    onFileChange(event, fileType, index ,type) {
      const files = event.target.files;
      if (files && files[0]) {
        const file = files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
          const base64String = reader.result;
          type[index][fileType] = base64String;
          console.log(this.adults[index]);
        };

        reader.readAsDataURL(file);
      }
    },
    fetchPackageData() {
      try {
        this.fetchedPackageData = JSON.parse(
          localStorage.getItem("packageData")
        );
        this.package_details = JSON.parse(
          localStorage.getItem("packageDetails")
        );
        this.package_prices = JSON.parse(localStorage.getItem("packagePrices"));

        let packageData = JSON.parse(localStorage.getItem("packageData"));

        packageData.package_rooms.forEach((ele) => {
          // console.log(ele, "wwww");
          this.number_of_adults = ele.adult_count;
          this.number_of_childrens = ele.child_count;
        });

        for (let i = 0; i < this.number_of_childrens; i++) {
          this.childrens.push({
            first_name: "",
            last_name: "",
            nationality_value: "",
            date_of_birth: "",
            email: "",
            passport_number: "",
            identity_type_value: "",
            expireDate: "",
            country_code: "",
            day: "",
            month: "",
            year: "",
            expir_day: "",
            expir_month: "",
            expir_year: "",
            id_photo: "",
            profile_photo: "",
            passport_photo: "",
          });
        }
        for (let i = 0; i < this.number_of_adults; i++) {
          this.adults.push({
            first_name: "",
            last_name: "",
            nationality_value: "",
            date_of_birth: "",
            email: "",
            passport_number: "",
            identity_type_value: "",
            expireDate: "",
            country_code: "",
            day: "",
            month: "",
            year: "",
            expir_day: "",
            expir_month: "",
            expir_year: "",
            id_photo: "",
            profile_photo: "",
            passport_photo: "",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    check_coupon() {
      let couponData = {
        coupon_type: "package",
        coupon_id: this.coupon_code,
        // coupon_id: parseInt(this.coupon_code),
        coupon_specification_id: JSON.parse(localStorage.getItem("packageData"))
          .package_id,
        total_price: this.package_prices.total_selling_after_tax,
        converter_status: 1,
      };

      axios
        .post(`/check_coupon`, couponData)
        .then(({ data }) => {
          // console.log(data);
          this.coupon_submit = true;
          this.price_after_coupon = data.data.final_value;
        })
        .catch((error) => {
          // console.log(error);
          Swal.fire({
            position: "center",
            icon: "error",
            title: error.response.data.message,
          });
        });
    },
    bookUmrah() {
      let packageData = JSON.parse(localStorage.getItem("packageData"));

      let bookData = {
        package_id: packageData.package_id,
        date: packageData.date,
        package_services: packageData.package_services,
        payment_method: this.type,
        package_rooms: packageData.package_rooms,
        package_guests: [],
        total_adults_price: this.package_prices.total_adults_price,
        total_children_price: this.package_prices.total_child_price,
        total_price: this.package_prices.total_selling,
        total_tax: this.package_prices.tax_value,
        total_price_after_tax: this.package_prices.total_selling_after_tax,
        status: 1,
        payment_status: 0,
        has_coupon: this.coupon_code == "" ? 0 : 1,
        coupon_code: this.coupon_code,
      };

      this.adults.forEach((ele) => {
        bookData.package_guests.push({
          Birthdate: `${ele.day.day}-${ele.month.number}-${ele.year.year}`,
          IdentityNumber: ele.passport_number,
          IdentityType: ele.identity_type_value.title,
          CountryCode: ele.country_code.code,
          ExpiryDate: `${ele.expir_day.day ? ele.expir_day.day : 5}-${
            ele.expir_month.number ? ele.expir_month.number : 1
          }-${ele.expir_year.year ? ele.expir_year.year : 2024}`,
          gender: "male",
          // gender: ele.gender_value.code,
          first_name: ele.first_name
            ? ele.first_name
            : !this.toggle_form_data
            ? this.user.first_name
            : ele.first_name,

          last_name: ele.last_name
            ? ele.last_name
            : !this.toggle_form_data
            ? this.user.last_name
            : ele.last_name,
          email: ele.email
            ? ele.email
            : !this.toggle_form_data
            ? this.user.email
            : ele.email,
          nationality: ele.nationality_value.title,
          title: "mr",
          passport_image: ele.passport_photo,
          id_image: ele.id_photo,
          personal_image: ele.profile_photo,
        });
      });

      this.childrens.forEach((ele) => {
        bookData.package_guests.push({
          Birthdate: `${ele.day.day ? ele.day.day : 5}-${
            ele.month.number ? ele.month.number : 1
          }-${ele.year.year ? ele.year.year : 2024}`,

          IdentityNumber: ele.passport_number,
          IdentityType: ele.identity_type_value.title,
          CountryCode: ele.country_code.code,
          ExpiryDate: `${ele.expir_day.day ? ele.expir_day.day : 5}-${
            ele.expir_month.number ? ele.expir_month.number : 1
          }-${ele.expir_year.year ? ele.expir_year.year : 2024}`,
          gender: "male",
          first_name: ele.first_name,
          last_name: ele.last_name,
          email: ele.email,
          nationality: ele.nationality_value.title,
          title: "mr",
          passport_image: ele.passport_photo,
          id_image: ele.id_photo,
          personal_image: ele.profile_photo,
        });
      });
      if (navigator.onLine) {
        axios
          .post(`/book_package`, bookData)
          .then(({ data }) => {
            if (this.type == 2) {
              if (data?.data?.payment_link?.payment_link != null) {
                window.location.href =
                  data?.data?.payment_link?.payment_link ?? null;
              } else {
                Swal.fire({
                  title: "",
                  text: data?.data?.payment_link?.message,
                  icon: "warning",
                });
              }
            } else if (this.type == 1) {
              Swal.fire({
                title: "",
                text: this.$t("pending_book"),
                icon: "succes",
              });
              this.$router.push("/umrahBookingProfile");
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      } else {
        this.bookUmrahOffline(bookData);
      }
    },
  },
  mounted() {
    if (this.toggle_form_data) {
      this.fetchPackageData();
    }
  },
  computed: {
    years() {
      const currentYear = new Date().getFullYear();
      const startYear = 1913;
      const yearArray = [];

      for (let year = startYear; year <= currentYear; year++) {
        yearArray.push({ id: yearArray.length + 1, year: year });
      }

      return yearArray;
    },
    yearsExpiryDate() {
      const currentYear = new Date().getFullYear();
      const endYear = currentYear + 10;
      const yearArray = [];

      for (let year = currentYear; year <= endYear; year++) {
        yearArray.push({ id: year, year: year });
      }

      return yearArray;
    },
    isFormValid() {
      if (!this.type) return false;

      const userFields = ["first_name", "last_name", "email", "phone"];
      const adultFields = [
        "gender_value",
        "first_name",
        "last_name",
        "nationality_value",
        "day",
        "month",
        "year",
        "identity_type_value",
        "passport_number",
        "country_code",
        "expir_day",
        "expir_month",
        "expir_year",
        "PhoneNumber",
      ];
      const childrenFields = [
        "first_name",
        "last_name",
        "nationality_value",
        "day",
        "month",
        "year",
        "identity_type_value",
        "passport_number",
        "country_code",
        "expir_day",
        "expir_month",
        "expir_year",
      ];
      const babyFields = [
        "first_name",
        "last_name",
        "nationality_value",
        "date_of_birth",
        "passport_number",
        "email",
      ];

      // Validation rules for toggle_form_data = true
      if (this.toggle_form_data) {
        const isUserValid = userFields.every((field) => this.user[field]);
        const areAdultsValid = this.adults.every((adult) =>
          adultFields.every((field) => adult[field])
        );
        const areChildrenValid = this.childrens.every((child) =>
          childrenFields.every((field) => child[field])
        );
        const areBabiesValid = this.babies.every((baby) =>
          babyFields.every((field) => baby[field])
        );

        return (
          isUserValid && areAdultsValid && areChildrenValid && areBabiesValid
        );
      } else {
        const areChildrenValid = this.childrens.every((child) =>
          childrenFields.every((field) => child[field])
        );

        return (
          this.user.first_name &&
          this.user.last_name &&
          this.user.email &&
          this.user.phone &&
          this.adults.every((adult) => {
            return (
              adult.gender_value &&
              adult.nationality_value &&
              adult.day &&
              adult.month &&
              adult.year &&
              adult.identity_type_value &&
              adult.passport_number &&
              adult.country_code &&
              adult.expir_day &&
              adult.expir_month &&
              adult.expir_year
            );
          }) &&
          areChildrenValid
        );
      }
    },
  },
};
</script>
