<template>
  <section class="places py-4">
    <div class="title d-flex align-items-start justify-content-between">
      <div class="text">
        <h2 class="fw-bold px-0">
          {{ $t("Most visited places in the world") }}
          <img src="@/assets/media/image/places.png" />
        </h2>
        <p class="text-secondary fs-18 fw-light">
          {{
            $t(
              "Travelers looking for the best places in the world also book here"
            )
          }}
        </p>
      </div>
      <!-- <button class="btn SecondButton">{{$t("see more")}}</button> -->
    </div>
    <div class="d-flex justify-content-center gap-3" v-if="loading">
      <div class="card mx-0 w-100" v-for="(item, index) in 2" :key="index">
        <Skeleton width="100%" height="150px"></Skeleton>
      </div>
    </div>
    <div class="places_grid" v-else>
      <div
        class="place"
        v-for="place in places"
        :key="place.id"
        @click="
          searchDestination(
            place.city == null ? '' : place.city.id,
            place.city == null ? '' : place.city.title,
            place.country == null ? '' : place.country.title,
            place.country == null ? '' : place.country.code
          )
        "
      >
        <img :src="place.image" />
        <div class="overlay">
          <div class=""></div>
          <div class="city_details">
            <div class="details">
              <h5 class="fw-bold">{{ place.title }}</h5>
              <div class="d-flex align-items-center mx-0">
                <rate :rates="place.rate" class="m-0" />
                <p class="text-white m-0">
                  ( {{ place.reviewers }} {{ $t("rate") }} )
                </p>
              </div>
            </div>
            <div class="trip_details d-flex">
              <div class="trip_number">
                {{ place.number_trip }} {{ $t("trip") }}
              </div>
              <div class="trip_price">
                <span>{{ $t("start from") }}</span> {{ place.price }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import rate from "@/components/rate.vue";
import axios from "axios";
import Skeleton from "primevue/skeleton";

export default {
  name: "place-component",
  data() {
    return {
      places: [],
      loading: true,
    };
  },
  methods: {
    searchDestination(city_id, city_title, country_name, country_code) {
      let today = new Date();
      let tomorrow = new Date(today);

      let dayAfterTomorrow = new Date(tomorrow);
      dayAfterTomorrow.setDate(tomorrow.getDate() + 1);

      let year = dayAfterTomorrow.getFullYear();
      let month = dayAfterTomorrow.getMonth() + 1;
      let day = dayAfterTomorrow.getDate();

      let formattedDayAfterTomorrow = `${year}-${month
        .toString()
        .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;

      let dayAfterTwoDay = new Date(today);
      dayAfterTwoDay.setDate(today.getDate() + 2);

      let yearAfterTwoDay = dayAfterTwoDay.getFullYear();
      let monthAfterTwoDay = dayAfterTwoDay.getMonth() + 1;
      let todayAfterTwoDay = dayAfterTwoDay.getDate();

      let formattedDayAfterTwoDays = `${yearAfterTwoDay}-${monthAfterTwoDay
        .toString()
        .padStart(2, "0")}-${todayAfterTwoDay.toString().padStart(2, "0")}`;

      this.loading = true; //the loading begin
      this.hideFilter = false;
      this.hotelData = JSON.parse(localStorage.getItem("hotelDataSearch"));

      const filterWebPeds = {
        city_id: city_id,
        city_name: city_title,
        country_name: country_name,
        country_code: country_code,
        nationality_code: "SA",
        no_of_rooms: 1,
        passenger_country_of_residence_code: "SA",
        converter_status: 1,
        rooms_guests: [
          {
            rate_basis: "1",
            adults_count: 1,
            children_count: 0,
            children_ages: [],
          },
        ],
        check_in: formattedDayAfterTomorrow,
        check_out: formattedDayAfterTwoDays,
        meta_data: {
          credential_id: 2,
          provider_id: 2,
        },
      };

      const filter = {
        city_id: city_id,
        converter_status: 1,
        provider_id: 2,
      };
      localStorage.setItem("hotelDataSearch", JSON.stringify(filterWebPeds));
      localStorage.setItem("hotelDataFilter", JSON.stringify(filter));
      this.$router.push("/hotelSearchNew");
    },
    async fetchPlaces() {
      await axios.get("/destinations").then(({ data }) => {
        this.places = data.data.data.slice(0, 6);
        this.loading = false;
      });
    },
  },
  components: {
    rate,
    Skeleton,
  },
  async created() {
    await this.fetchPlaces();
  },
};
</script>
<!-- <template>
  <section class="testi_sec">
    <div class="text">
      <h2 class="fw-bold px-0">
        {{ $t("Most visited places in the world") }}
        <img src="@/assets/media/image/places.png" />
      </h2>
      <p class="text-secondary fs-18 fw-light">
        {{
          $t(
            "Travelers looking for the best places in the world also book here"
          )
        }}
      </p>
    </div>
    <swiper
      :slidesPerView="3"
      :spaceBetween="30"
      :pagination="{
        clickable: true,
      }"
      :modules="modules"
      class="mySwiper"
      :breakpoints="{
        '0': {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        '768': {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        '1024': {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      }"
      :navigation="true"
    >
      <swiper-slide v-for="(place, index) in places" :key="index">
        <div
          class="place"
          @click="
            searchDestination(
              place.city == null ? '' : place.city.id,
              place.city == null ? '' : place.city.title,
              place.country == null ? '' : place.country.title,
              place.country == null ? '' : place.country.code
            )
          "
        >
          <img :src="place.image" />
          <div class="overlay">
            <div class=""></div>
            <div class="city_details">
              <div class="details">
                <h5 class="fw-bold">{{ place.title }}</h5>
                <div class="d-flex align-items-center mx-0">
                  <rate :rates="place.rate" class="m-0" />
                  <p class="text-white m-0">
                    ( {{ place.reviewers }} {{ $t("rate") }} )
                  </p>
                </div>
              </div>
              <div class="trip_details d-flex">
                <div class="trip_number">
                  {{ place.number_trip }} {{ $t("trip") }}
                </div>
                <div class="trip_price">
                  <span>{{ $t("start from") }}</span> {{ place.price }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import axios from "axios";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import rate from "@/components/rate.vue";
import "swiper/css";

import "swiper/css/pagination";

//   import './style.css';

// import required modules
import { Pagination, Navigation } from "swiper/modules";

import "swiper/css/navigation";

export default {
  data() {
    return {
      places: [],
    };
  },
  setup() {
    return {
      modules: [Pagination, Navigation],
    };
  },
  methods: {
    searchDestination(city_id, city_title, country_name, country_code) {
      let today = new Date();
      let tomorrow = new Date(today);

      let dayAfterTomorrow = new Date(tomorrow);
      dayAfterTomorrow.setDate(tomorrow.getDate() + 1);

      let year = dayAfterTomorrow.getFullYear();
      let month = dayAfterTomorrow.getMonth() + 1;
      let day = dayAfterTomorrow.getDate();

      let formattedDayAfterTomorrow = `${year}-${month
        .toString()
        .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;

      let dayAfterTwoDay = new Date(today);
      dayAfterTwoDay.setDate(today.getDate() + 2);

      let yearAfterTwoDay = dayAfterTwoDay.getFullYear();
      let monthAfterTwoDay = dayAfterTwoDay.getMonth() + 1;
      let todayAfterTwoDay = dayAfterTwoDay.getDate();

      let formattedDayAfterTwoDays = `${yearAfterTwoDay}-${monthAfterTwoDay
        .toString()
        .padStart(2, "0")}-${todayAfterTwoDay.toString().padStart(2, "0")}`;

      this.loading = true; //the loading begin
      this.hideFilter = false;
      this.hotelData = JSON.parse(localStorage.getItem("hotelDataSearch"));

      const filterWebPeds = {
        city_id: city_id,
        city_name: city_title,
        country_name: country_name,
        country_code: country_code,
        nationality_code: "SA",
        no_of_rooms: 1,
        passenger_country_of_residence_code: "SA",
        converter_status: 1,
        rooms_guests: [
          {
            rate_basis: "1",
            adults_count: 1,
            children_count: 0,
            children_ages: [],
          },
        ],
        check_in: formattedDayAfterTomorrow,
        check_out: formattedDayAfterTwoDays,
        meta_data: {
          credential_id: 2,
          provider_id: 2,
        },
      };

      const filter = {
        city_id: city_id,
        converter_status: 1,
        provider_id: 2,
      };
      localStorage.setItem("hotelDataSearch", JSON.stringify(filterWebPeds));
      localStorage.setItem("hotelDataFilter", JSON.stringify(filter));
      this.$router.push("/hotelSearchNew");
    },
    async fetchPlaces() {
      await axios.get("/destinations").then(({ data }) => {
        this.places = data.data.data.slice(0, 6);
        this.loading = false;
      });
    },
  },
  components: {
    rate,
    Swiper,
    SwiperSlide,
  },
  async created() {
    await this.fetchPlaces();
  },
};
</script> -->
