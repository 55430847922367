<template>
  <div v-for="(hotel, index) in hotelsBooking" :key="index" class="main-card">
    <router-link :to="`hotel-booking/${hotel.id}`" class="card-bookingbody">
      <div class="card-img">
        <img
          @error="setDefaultImage"
          v-if="hotel.hotels !== null"
          :src="hotel.hotels.images[0].image"
          class="img-bg"
        />
        <img v-else src="@/assets/media/image/logo.png" alt="" />
      </div>
      <div class="details_reservation">
        <div class="rate">
          <h3 v-if="hotel.hotel_name">{{ hotel.hotel_name }}</h3>
          <div class="date d-flex flex-column justify-content-center px-2">
            <span
              :class="[
                'badge',
                'py-2',
                'mb-2',
                'text-white',
                hotel.status === 0
                  ? 'bg-primary'
                  : hotel.status === 1
                  ? 'bg-warning text-dark'
                  : hotel.status === 2
                  ? 'bg-success'
                  : hotel.status === 3
                  ? 'bg-info text-dark'
                  : hotel.status === 4
                  ? 'bg-danger'
                  : '',
              ]"
            >
              {{
                hotel.status === 0
                  ? $t("initial")
                  : hotel.status === 1
                  ? $t("pending")
                  : hotel.status === 2
                  ? $t("completed")
                  : hotel.status === 3
                  ? $t("edited")
                  : hotel.status === 4
                  ? $t("canceled")
                  : ""
              }}
            </span>
            <div>
              <i class="fa-regular fa-calendar-check"></i>
              {{ $t("reservation_date") }}:
              {{ hotel.created_date }}
            </div>
          </div>
        </div>
        <div class="all-details_reservation">
          <div class="contact">
            <div class="fir_info">
              <h4>
                <i class="fa-solid fa-location-dot"></i>
                {{
                  hotel.hotels == null || hotel.hotels.city == null
                    ? ""
                    : hotel.hotels.city.title
                }}
                ,
                {{
                  hotel.hotels == null || hotel.hotels.country == null
                    ? ""
                    : hotel.hotels.country.title
                }}
                <!-- <span><a href="#">عرض علي الخريطة</a></span>  -->
              </h4>
              <h4>
                <i class="fa-regular fa-calendar-check"></i>
                {{ $t("Arrival date") }}:
                {{ hotel.check_in }}
              </h4>
              <h4>
                <i class="fa-regular fa-calendar-xmark"></i>
                {{ $t("Departure Date") }} :
                {{ hotel.check_out }}
              </h4>
              <h4>
                <i class="fa-solid fa-moon"></i>
                {{ $t("number_night") }} :
                {{ calculateDateDifference(hotel.check_in, hotel.check_out) }}
              </h4>
            </div>
            <div class="sec_info">
              <h4>
                <i class="fa-solid fa-dollar-sign"></i>
                {{ $t("price_before_tax") }}:
                <span>{{ convertedPrice(hotel.overall_selling_price) }}</span>
                {{ coinCode }}
                <!-- {{ hotel }} -->
                <!-- {{ convertedPrice(hotel.Rooms[0].selling_price) }} {{coinCode}} -->
              </h4>
              <!-- <h4>
              <i class="fa-solid fa-file-invoice-dollar"></i>
              {{ $t("tax_value") }} :
              {{ convertedPrice(hotel.selling_price_tax) }} {{coinCode}}
            </h4> -->
              <h4>
                <i class="fa-solid fa-dollar-sign"></i>
                {{ $t("price_after_tax") }} :
                <span>{{ convertedPrice(hotel.overall_selling_price) }}</span>
                {{ coinCode }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </router-link>
    <hr />
    <div class="last-btncard">
      <!-- Button trigger modal -->
      <button
        type="button"
        class="btn btn-primary receipt"
        data-bs-toggle="modal"
        :data-bs-target="`#SendReceipt${hotel.id}`"
        v-if="
          hotel.payment_method == 1 &&
          (hotel.receipts.length > 0
            ? hotel.receipts[0].image == ''
            : false || hotel.receipts.length == 0)
        "
      >
        {{ $t("Raise_the_originals") }}
      </button>

      <button
        class="btn btn-success"
        type="button"
        data-bs-toggle="modal"
        :data-bs-target="`#ShowReceipt${hotel.id}`"
        v-if="hotel.receipts.length > 0 ? hotel.receipts[0].image != '' : false"
      >
        {{ $t("show reciept") }}
      </button>

      <router-link
        :to="`hotel-booking/${hotel.id}`"
        class="btn button_reservation"
        >{{ $t("show details") }}</router-link
      >
      <!-- Modal -->
      <sendRececipt
        :book_id="hotel.id"
        :book_type="1"
        @fetchHotel="fetchHotel"
      />
      <showReceipt
        :book_id="hotel.id"
        :image="hotel?.receipts[0]?.image"
        :book_type="1"
      />
    </div>
  </div>
</template>

<script>
import sendRececipt from "@/components/sendRececipt/sendReceipt.vue";
import showReceipt from "@/components/sendRececipt/showReceipt.vue";

export default {
  props: {
    hotelsBooking: Array,
    rooms: Array,
  },
  data() {
    return {
      coinCode:
        localStorage.getItem("coinCode") == null
          ? "USD"
          : localStorage.getItem("coinCode"),
    };
  },
  components: {
    sendRececipt,
    showReceipt,
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = localStorage.getItem("DefaultImage");
      this.isError = true; // Set isError to true when an error occurs
    },
    calculateDateDifference(checkIn, checkOut) {
      const startDate = new Date(checkIn);
      const endDate = new Date(checkOut);

      // Calculate the difference in milliseconds
      const difference = endDate - startDate;

      // Convert the difference to days
      const daysDifference = difference / (1000 * 60 * 60 * 24);

      return daysDifference;
    },
    fetchHotel() {
      this.$emit("fetchHotel");
    },
  },
};
</script>
