<template>
  <footer>
    <!-- <img :src="logo" class="circle-footer" /> -->
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="info_column">
            <router-link to="/">
              <img class="logo" :src="logo" />
            </router-link>
            <p>
              {{
                $t(
                  "Booking, reviews and advice on hotels, resorts, flights, vacation rentals, travel packages and more"
                )
              }}
            </p>
            <ul class="info">
              <li class="d-flex align-items-center">
                <img src="@/assets/media/image/call-calling.svg" alt="" />
                <div class="info-body">
                  <!-- <div class="info-text" onclick="window.open('tel:201003802708');">+201003802708</div> -->
                  <div class="info-text">
                    <a :href="`tel:${phone}`">{{ phone }}</a>
                  </div>
                </div>
              </li>
              <li class="d-flex align-items-center">
                <img src="@/assets/media/image/sms.svg" alt="" />
                <div class="info-body">
                  <div class="info-text">
                    <a :href="`mailto:${email}`">{{ email }}</a>
                  </div>
                  <!-- <div class="info-text" @click='window.location.href = "mailto:yasmeensoliman448@gmail.com?subject=Subject&body=message%20goes%20here"'>info@travelsofttech.com</div> -->
                </div>
              </li>
              <li class="d-flex">
                <img src="@/assets/media/image/Icon.svg" alt="" />
                <div class="info-body">
                  <div class="info-text">
                    <a :href="`${address}`">{{ address }}</a>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="license">
              <li>
                <span>{{ $t("license_number") }}: </span>{{ license_number }}
              </li>
              <li>
                <span>{{ $t("commercial_registration_number") }}: </span>
                {{ commercial_registration_number }}
              </li>
            </ul>
          </div>
        </div>

        <div class="col-6 col-md-3 col-lg-2">
          <div class="links">
            <div class="title">
              <h5>{{ $t("company") }}</h5>
              <!-- <img src="@/assets/media/image/signal-line.png" /> -->
            </div>
            <ul>
              <li>
                <router-link to="/" class="footer-link">{{
                  $t("main")
                }}</router-link>
              </li>
              <template v-for="(service, index) in services" :key="index">
                <li v-if="service.service_name === 'FLIGHT'">
                  <router-link to="/flights" class="footer-link">{{
                    $t("Flights")
                  }}</router-link>
                </li>
                <li v-if="service.service_name === 'HOTEL'">
                  <router-link to="/hotels" class="footer-link">{{
                    $t("Hotels")
                  }}</router-link>
                </li>
                <li v-if="service.service_name === 'PACKAGE'">
                  <router-link to="/package/umrah" class="footer-link">{{
                    $t("UMRAH PACKAGES")
                  }}</router-link>
                </li>
              </template>
              <li>
                <router-link to="/cardSale" class="footer-link">{{
                  $t("offers")
                }}</router-link>
              </li>
              <li v-if="webPath == 'https://kanaya.x-coders.net'">
                <router-link to="/sign-in" class="footer-link"
                  >Affiliate</router-link
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="col-6 col-md-3 col-lg-2">
          <div class="links">
            <div class="title">
              <h5>{{ $t("other links") }}</h5>
              <!-- <img src="@/assets/media/image/signal-line.png" /> -->
            </div>
            <ul>
              <li>
                <router-link to="/contact" class="footer-link">{{
                  $t("contact us")
                }}</router-link>
              </li>
              <li>
                <router-link to="/about" class="footer-link">{{
                  $t("about us")
                }}</router-link>
              </li>
              <li>
                <router-link to="/blogs" class="footer-link">{{
                  $t("blogs")
                }}</router-link>
              </li>
              <li>
                <router-link to="/terms" class="footer-link">{{
                  $t("Terms and conditions")
                }}</router-link>
              </li>
              <li>
                <router-link to="/policy" class="footer-link">{{
                  $t("Policies")
                }}</router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 mb-2">
          <div class="subscribe_column">
            <div class="title">
              <h5>{{ $t("Know everything new and new") }}</h5>
              <p>
                {{
                  $t(
                    "Subscribe now to find out the latest offers about hotels, flights, and tourist resorts"
                  )
                }}
              </p>
            </div>
            <form @submit.prevent="subscripe()">
              <div class="">
                <i class="fa-regular fa-envelope"></i>
                <input
                  type="text"
                  name="phone"
                  v-model="phone_subpscription"
                  :placeholder="$t('enter_your_phone')"
                />
                <button class="btn subscribe">{{ $t("Subscripe") }}</button>
              </div>
            </form>
            <ul class="social d-flex align-items-center">
              <li class="icon-container" v-if="facebook != ''">
                <a :href="facebook" target="_blank"
                  ><i class="fa-brands fa-facebook-f"></i
                ></a>
              </li>
              <li class="icon-container" v-if="instagram != ''">
                <a :href="instagram" target="_blank"
                  ><i class="fa-brands fa-instagram"></i
                ></a>
              </li>
              <li class="icon-container" v-if="linkedin != ''">
                <a :href="linkedin" target="_blank"
                  ><i class="fa-brands fa-linkedin-in"></i
                ></a>
              </li>
              <li class="icon-container" v-if="twitter != ''">
                <a :href="twitter" target="_blank"
                  ><i class="fa-brands fa-twitter"></i
                ></a>
              </li>
              <li class="icon-container" v-if="snapchat != ''">
                <a :href="snapchat" target="_blank"
                  ><i class="fa-brands fa-snapchat"></i
                ></a>
              </li>
              <li class="icon-container" v-if="tiktok != ''">
                <a :href="tiktok" target="_blank"
                  ><i class="fa-brands fa-tiktok"></i
                ></a>
              </li>
              <li class="icon-container" v-if="whatsapp != ''">
                <a :href="whatsapp" target="_blank"
                  ><i class="fa-brands fa-whatsapp"></i
                ></a>
              </li>
              <li class="icon-container" v-if="youtube != ''">
                <a :href="youtube" target="_blank"
                  ><i class="fa-brands fa-youtube"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <hr class="pb-0 mb-0" />
    <div class="company_info py-2">
      <div
        class="d-flex align-items-center justify-content-around company_info_data"
      >
        <p>
          &copy; {{ $t("all rights are save") }} @ {{ $t(title ?? "") }}
          {{ $t("website") }} 2023
        </p>
        <p>{{ $t("Made by X Coders coding with expert") }}</p>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "footer-layout",
  data() {
    return {
      logo: null,
      address: "",
      email: "",
      phone: "",
      license_number: null,
      commercial_registration_number: null,
      facebook: "",
      instagram: "",
      linkedin: "",
      twitter: "",
      snapchat: "",
      tiktok: "",
      whatsapp: "",
      youtube: "",
      title: "",
      services: [],
      webPath: window.location.origin,
      message: "",
      phone_subpscription: "",
    };
  },
  methods: {
    fetchOrganizationData() {
      axios.get("/organization_settings_index").then(({ data }) => {
        this.title = data.data == null ? null : data.data.title;
        this.logo = data.data == null ? null : data.data.logo;
        this.email = data.data == null ? null : data.data.email;
        this.phone = data.data == null ? null : data.data.phone;
        this.license_number =
          data.data == null ? null : data.data.license_number;
        this.commercial_registration_number =
          data.data == null ? null : data.data.commercial_registration_number;
        this.address = data.data == null ? null : data.data.address;
        this.facebook = data.data == null ? null : data.data.facebook;
        this.instagram = data.data == null ? null : data.data.instgram;
        this.linkedin = data.data == null ? null : data.data.linkedin;
        this.twitter = data.data == null ? null : data.data.twitter;
        this.snapchat = data.data == null ? null : data.data.snapchat;
        this.tiktok = data.data == null ? null : data.data.tiktok;
        this.whatsapp = data.data == null ? null : data.data.whatsapp;
        this.youtube = data.data == null ? null : data.data.youtube;
        this.services =
          data?.data && data?.data?.services.length > 0 && data?.data?.services;
      });
    },

    subscripe() {
      axios
        .post("/store_news_letter_phone", { phone: this.phone_subpscription })
        .then(({ data }) => {
          this.message = data.data;
          Swal.fire({
            position: "center",
            icon: "success",
            title: data.message,
            timer: 3000,
          });
          window.open(`${this.whatsapp}`, "_blank");
          this.phone_subpscription = "";
        })
        .catch((error) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: error?.response?.data?.message,
            timer: 3000,
          });
          return error;
        })
        .finally(() => {});
    },
  },
  created() {
    this.fetchOrganizationData();
  },
};
</script>
