<template>
  <div class="row">
    <div class="col-lg-6 col-12">
      <div class="d-flex">
        <div class="head">
          <h2>{{ hotelData == null ? null : hotelData.title }}</h2>
        </div>
        <rate :rates="hotelData.starCount" />
      </div>
      <ul class="location">
        <li class="d-flex align-items-center justify-content-between">
          <div class="d-flex flex-column">
            <p>
              <i class="fa-solid fa-location-dot"></i>
              {{ hotelData == null ? null : hotelData.address }}
            </p>
            <p>
              <i class="fa-solid fa-bed"></i> {{ hotelData.no_of_rooms }}
              {{ $t("the room") }}
            </p>
          </div>
          <div class="d-flex flex-column">
            <p>
              <i class="fa-regular fa-clock"></i> {{ $t("check_in") }}
              {{ hotelData.check_in }}
            </p>
            <p>
              <i class="fa-regular fa-clock"></i> {{ $t("check_out") }}
              {{ hotelData.check_out }}
            </p>
          </div>
        </li>
      </ul>
      <div class="address_hotel">
        <div class="data">
          <a
            class="show_map"
            :href="`https://www.google.com/maps?q=${
              hotelData == null ? null : hotelData.lat
            },${hotelData == null ? null : hotelData.long}&z=${this.zoom}`"
            target="_blank"
            >{{ $t("show_map") }}</a
          >
        </div>
        <div class="map">
          <GoogleMap
            :center="{
              lat: parseFloat(hotelData == null ? null : hotelData.lat),
              lng: parseFloat(hotelData == null ? null : hotelData.long),
            }"
            :zoom="zoom"
            :api-key="googleMapsApiKey"
          >
            <Marker
              :position="{ lat: parseFloat(hotelData == null ? null : hotelData.lat), lng: parseFloat(hotelData == null ? null : hotelData.long) }"
              :icon="customIcon"
            />
          </GoogleMap>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-12">
      <div class="images">
        <div class="show_images">
          <div
            class="single_image"
            v-for="(img, index) in MyCurrentImages"
            :key="index"
          >
            <img
              @error="setDefaultImage"
              :src="
                img.image
                  ? img.image
                  : 'https://softtech.crazyidea.online/uploads/organization_settings/898951703760775.png'
              "
              :alt="index"
              :class="{ 'error-image': isError }"
              data-bs-toggle="modal"
              :data-bs-target="`#imageModal${index}`"
            />

            <!-- <div class="number_badge" v-if="index==0">
              +<span>{{images.length}}</span>
              <i class="fa-regular fa-images"></i>
            </div> -->
          </div>
          <div
            class="modal fade"
            v-for="(img, index) in images == null ? null : images"
            :key="index"
            :id="`imageModal${index}`"
            tabindex="-1"
            aria-labelledby="imageModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="head_gallery">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <p class="length">
                      {{ images == null ? null : images.length }} /
                      {{ index + 1 }}
                    </p>
                  </div>
                  <div class="image">
                    <i
                      class="fa-solid fa-chevron-right"
                      data-bs-toggle="modal"
                      :data-bs-target="`#imageModal${index + 1}`"
                      :class="{ disable: index + 1 == images.length }"
                    ></i>
                    <img :src="images[index].image" :alt="index" />
                    <i
                      class="fa-solid fa-chevron-left"
                      data-bs-toggle="modal"
                      :data-bs-target="`#imageModal${index - 1}`"
                      :class="{ disable: index + 1 == 1 }"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="number_badge">
            +<span>{{ images == null ? null : images.length }}</span>
            <i class="fa-regular fa-images"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GoogleMap, Marker } from "vue3-google-map";
import rate from "../rate.vue";
// import moment from "moment";

export default {
  name: "image-detials-hotel",
  props: {
    images: Array,
    hotelData: Object,
  },
  components: {
    GoogleMap,
    Marker,
    rate,
  },
  data() {
    return {
      customIcon: {
        url: "https://cdn-icons-png.flaticon.com/512/11083/11083698.png",
        scaledSize: {
          width: 50,
          height: 50,
        },
      },
      isError: false,
      zoom: 15, // Default zoom level,
    };
  },
  computed: {
    MyCurrentImages() {
      return this.images == null ? null : this.images.slice(0, 5);
    },
    googleMapsApiKey() {
      return process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
    },
  },
  methods: {
    formatTime(time) {
      var timeString = time;
      var timeParts = timeString.split(":");
      var hours = parseInt(timeParts[0], 10);
      var minutes = parseInt(timeParts[1], 10);

      var date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);

      var formattedTime = date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      return formattedTime;
    },
    setDefaultImage(event) {
      event.target.src = localStorage.getItem("DefaultImage");
      this.isError = true; // Set isError to true when an error occurs
    },
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_images.scss";
</style> -->
