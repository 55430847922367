<template>
  <nav class="navbar main_navbar navbar-expand-lg navbar-template-2 new-navbar">
    <!--    :class="{-->
    <!--    'navbar-template-1': theme === 1,-->
    <!--    'navbar-template-2': theme !== 1,-->
    <!--    NavBarColor: showNavBar,-->
    <!--    'new-navbar': !isSecondColorRoute($route),-->
    <!--    }"-->
    <!-- :style="!showNavBar ? 'display: none;' : ''" -->
    <div class="container">
      <router-link class="navbar-brand" to="/"
        ><img :src="logo" alt="logo"
      /></router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mb-2 mb-lg-0">
          <template v-for="(service, index) in sortedServices" :key="index">
            <li class="nav-item" v-if="service.service_name === 'HOTEL'">
              <router-link to="/hotels" class="nav-link">
                <i class="fa-solid fa-hotel"></i>{{ $t("Hotels") }}
              </router-link>
            </li>
            <li class="nav-item" v-if="service.service_name === 'FLIGHT'">
              <router-link class="nav-link" to="/flights">
                <i class="fa-solid fa-plane-departure"></i>
                {{ $t("Flight") }}
              </router-link>
            </li>
            <li class="nav-item" v-if="service.service_name === 'PACKAGE'">
              <router-link class="nav-link" to="/package/umrah">
                <i class="fa-solid fa-kaaba"></i>
                {{ $t("UMRAH PACKAGES") }}
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="service.service_name === 'TravelPackage'"
            >
              <router-link class="nav-link" to="/package/tourism">
                <i class="fa-solid fa-kaaba"></i>
                {{ $t("TravelPackage") }}
              </router-link>
            </li>
          </template>
          <li class="nav-item">
            <router-link class="nav-link" to="/cardSale">
              <i class="fa-solid fa-ticket"></i>
              {{ $t("offers") }}
            </router-link>
          </li>
        </ul>

        <div class="nav-item dropdown coin_dropdown">
          <a
            class="nav-link dropdown-toggle d-flex align-items-center text-white"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span>{{ coinCodeDisplays }}</span>
          </a>
          <coinCode @coinCodeDisplay="coinCodeDisplay" />
        </div>

        <div class="setting">
          <div class="lang">
            <div class="dropdown">
              <button
                class="btn dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <flag
                  :iso="langFlagStorage"
                  v-bind:squared="false"
                  class="mx-2"
                />
                <!-- {{langStorage == null ? $t("arabic") : $t(langStorage)}} -->
              </button>
              <ul class="dropdown-menu">
                <li
                  v-for="(language, index) in languages"
                  :key="index"
                  @click="changeLocale(language.language, language.flag)"
                >
                  <span class="dropdown-item">
                    <flag
                      :iso="language.flag"
                      v-bind:squared="false"
                      class="mx-2"
                    />
                    {{ language.title }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <notifications />
          <div
            class="dropdown"
            v-if="
              auth &&
              $route.path !== '/sign-in' &&
              $route.path !== '/new-acount'
            "
          >
            <button
              class="btn dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src="../../assets/media/image/icon/user.svg" alt="user" />
              {{ userName }}
            </button>
            <ul class="dropdown-menu">
              <!-- <li><router-link class="dropdown-item" to="/profile">{{$t("profile_info")}}</router-link></li> -->
              <li>
                <router-link
                  class="dropdown-item text-dark"
                  :to="{ name: 'personalData' }"
                  ><i class="fa-regular fa-file-lines"></i>
                  {{ $t("account_data") }}
                </router-link>
                <button
                  type="button"
                  class="dropdown-item text-danger logout_btn"
                  @click="removeToken"
                >
                  <i class="fa-solid fa-arrow-right-from-bracket"></i>
                  {{ $t("logout") }}
                </button>
              </li>
            </ul>
          </div>
          <div class="nav-item dropdown login_dropdown" v-else>
            <a
              class="nav-link dropdown-toggle d-flex align-items-center"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fa-regular fa-user"></i>
              <div class="mx-2">{{ $t("login") }}</div>
            </a>
            <div class="dropdown-menu">
              <div class="image">
                <img src="@/assets/media/image/login-photo.png" />
              </div>
              <h4>{{ $t("Log in now Don't miss much investigation..!") }}</h4>
              <p>
                {{ $t(`Log_enable_offers_flights`) }}
              </p>
              <router-link to="/login" class="btn PrimaryButton w-100">{{
                $t("login")
              }}</router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="setting_mobile align-items-center">
        <div class="nav-item dropdown coin_dropdown">
          <a
            class="nav-link dropdown-toggle d-flex align-items-center text-white"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span>{{ coinCodeDisplays }}</span>
          </a>
          <!-- <ul class="dropdown-menu">
            <li
              v-for="(coin, index) in coinCodes"
              :key="index"
              @click="changeCoinCode(`${coin.code} `)"
            
            >
              <div class="">{{ coin.code }}</div>
              <div class="">{{ coin.title }}</div>
            </li>
          </ul> -->
          <coinCode @coinCodeDisplay="coinCodeDisplay" />
        </div>

        <div class="notifications mx-2">
          <router-link to="/notifications" class="position-relative">
            <i class="fa fa-solid fa-bell text-light"> </i>

            <span
              v-if="unreadNotificationsCount > 0"
              class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
            >
              {{ unreadNotificationsCount }}
            </span>
          </router-link>
        </div>

        <div class="lang">
          <div class="dropdown">
            <button
              class="btn dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <flag
                :iso="langFlagStorage"
                v-bind:squared="false"
                class="mx-2"
              />
              <!-- {{langStorage == null ? $t("arabic") : $t(langStorage)}} -->
            </button>
            <ul
              class="dropdown-menu"
              :style="
                $root.$i18n.locale === 'ar'
                  ? 'left: 0 !important;'
                  : 'right: 0 !important;'
              "
            >
              <li
                v-for="(language, index) in languages"
                :key="index"
                @click="changeLocale(language.language, language.flag)"
              >
                <span class="dropdown-item">
                  <flag
                    :iso="language.flag"
                    v-bind:squared="false"
                    class="mx-2"
                  />
                  {{ language.title }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <div class="navigation">
    <ul>
      <template v-for="(service, index) in services" :key="index">
        <li
          class="list"
          :class="{ active: $route.path === '/cardSale' }"
          v-if="
            webPath == 'https://kanaya.x-coders.net' &&
            service.service_name === 'PACKAGE'
          "
        >
          <router-link to="/cardSale">
            <span class="icon">
              <ion-icon name="ticket-outline"></ion-icon>
              <!-- <ion-icon :icon="ticketOutline"></ion-icon> -->
            </span>
            <span class="text">{{ $t("offers") }}</span>
          </router-link>
        </li>
        <li
          class="list"
          :class="{ active: $route.path === '/hotels' }"
          v-if="service.service_name === 'HOTEL'"
        >
          <router-link to="/hotels">
            <span class="icon">
              <ion-icon name="business-outline"></ion-icon>
              <!-- <ion-icon :icon="businessOutline"></ion-icon> -->
            </span>
            <span class="text">{{ $t("Hotels") }}</span>
          </router-link>
        </li>
      </template>
      <li class="list" :class="{ active: $route.path === '/' }">
        <router-link to="/">
          <span class="icon">
            <ion-icon name="home-outline"></ion-icon>
            <!-- <ion-icon :icon="homeOutline"></ion-icon> -->
          </span>
          <span class="text">{{ $t("home") }}</span>
        </router-link>
      </li>
      <template v-for="(service, index) in services" :key="index">
        <li
          class="list"
          :class="{ active: $route.path === '/flights' }"
          v-if="service.service_name === 'FLIGHT'"
        >
          <router-link to="/flights">
            <span class="icon">
              <ion-icon name="airplane-outline"></ion-icon>
              <!-- <ion-icon :icon="airplaneOutline"></ion-icon> -->
            </span>
            <span class="text">{{ $t("Flight") }}</span>
          </router-link>
        </li>
      </template>
      <li
        class="list"
        :class="{
          active: $route.path === '/personal-data' || $route.path === '/login',
        }"
      >
        <router-link to="/personal-data">
          <span class="icon">
            <ion-icon name="person-circle-outline"></ion-icon>
            <!-- <ion-icon :icon="personCircleOutline"></ion-icon> -->
          </span>
          <span class="text">{{ $t("profile") }}</span>
        </router-link>
      </li>
      <div class="indicator"></div>
    </ul>
  </div>
</template>

<script>
// import axios from "axios";
import notifications from "@/components/notifications/notifications.vue";
import { organizationSettings } from "@/store/modules/organizationSettings";
import coinCode from "@/components/coins/coinCode.vue";
import { mapState } from "pinia";
export default {
  name: "navbar-layout",
  data() {
    return {
      theme: JSON.parse(localStorage.getItem("Theme")),
      // ticketOutline: ticketOutline,
      // homeOutline: homeOutline,
      // businessOutline: businessOutline,
      // airplaneOutline: airplaneOutline,
      // personCircleOutline: personCircleOutline,
      auth: false,
      userName:
        JSON.parse(localStorage.getItem("user")) == null
          ? ""
          : JSON.parse(localStorage.getItem("user")).name,
      languages: [
        { flag: "us", language: "en", title: this.$i18n.t("english") },
        { flag: "sa", language: "ar", title: this.$i18n.t("arabic") },
        { flag: "id", language: "id", title: this.$i18n.t("indonosia") },
        // { flag: "tr", language: "tr", title: "Turkish" },
      ],
      coinCodeDisplays: localStorage.getItem("coinCode")
        ? localStorage.getItem("coinCode")
        : "USD",
      langStorage: localStorage.getItem("lang"),
      langFlagStorage:
        localStorage.getItem("flagLang") == null
          ? "sa"
          : localStorage.getItem("flagLang"),
      webPath: "https://kanaya.x-coders.net", //window.location.origin,
      // coinCodes: [],
      showNavBar: false,
    };
  },

  methods: {
    coinCodeDisplay(data) {
      this.coinCodeDisplays =
        data || localStorage.getItem("coinCode")
          ? localStorage.getItem("coinCode")
          : "USD";
    },
    isSecondColorRoute(route) {
      // console.log("Current route name:", route.path);
      const includes = [
        "/cardSale",
        "/hotels",
        "/flights",
        "/umrah_package",
        "/",
      ].includes(route.path);
      // console.log("Includes:", includes);
      return includes;
    },
    handleScroll() {
      if (window.scrollY > 300) {
        this.showNavBar = true;
      } else {
        this.showNavBar = false;
      }
    },
    removeToken: function () {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      this.$router.go("/");
    },
    changeLocale(locale, flag) {
      this.$i18n.locale = locale;
      const html = document.documentElement; // returns the html tag
      html.setAttribute("lang", locale);
      // this.$forceUpdate('lang',locale);
      localStorage.setItem("lang", locale);
      localStorage.setItem("flagLang", flag);
      location.reload();
      // console.log(locale);
    },
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);

    this.auth = this.$store.getters["isAuthenticated"];
    // console.log("Auth : " + this.auth);

    // changeLocale(locale)
    const html = document.documentElement; // returns the html tag
    html.setAttribute("lang", this.$i18n.locale);
    // console.log(i18n.locale);

    this.profile = JSON.parse(localStorage.getItem("user"));
    // this.fetchCurrency();

    // console.log("coinCode", this.coinCode);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapState(organizationSettings, ["services", "logo"]),
    sortedServices() {
      const order = ["HOTEL", "FLIGHT", "PACKAGE", "TravelPackage"];
      return this.services.slice().sort((a, b) => {
        return order.indexOf(a.service_name) - order.indexOf(b.service_name);
      });
    },
  },

  components: {
    notifications,
    coinCode,
  },
};
</script>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const unreadNotificationsCount = computed(() => {
  return store.state.notifications.filter(
    (notification) => notification.is_read === "UnRead"
  ).length;
});
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_navbar.scss";
</style> -->
