<template>
  <ul class="d-flex mb-2">
    <!-- {{ rates }} -->
    <li v-for="(rate, index) in activeStars" :key="index">
      <i class="fa-solid fa-star"></i>
    </li>
    <li v-for="(rate, index) in remainingStars" :key="index">
      <i class="fa-regular fa-star"></i>
    </li>
  </ul>
</template>

<script>
export default {
  name: "rate-component",
  props: ["rates"],
  computed: {
    activeStars() {
      if (this.rates == null || this.rates == 0 || this.rates == "0") {
        return 0;
      } else if (this.rates == 2 || this.rates == "2") {
        return 2;
      } else if (this.rates == 3 || this.rates == "3") {
        return 3;
      } else if (this.rates == 4 || this.rates == "4") {
        return 4;
      } else if (this.rates == 5 || this.rates == "5") {
        return 5;
      } else {
        return 0;
      }
    },

    remainingStars() {
      return 5 - this.activeStars ?? 0;
      // const emptyStars = 5 - (this.rates || 0);
      // return emptyStars > 0 ? new Array(emptyStars) : 0;
    },
  },
};
</script>

<!-- <style scoped>
@import "./_rate.scss";
</style> -->
