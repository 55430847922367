<template>
  <div class="container">
    <div class="row">
      <div class="col-12 hotel_services">
        <h2 class="mx-0 px-0">{{ $t("all_attachment") }}</h2>
        <div class="card_lists">
          <!-- v-if="amenities.length" -->
          <div class="card_list">
            <h5><i class="fa-solid fa-bed"></i> {{ $t("relax_method") }}</h5>
            <ul v-if="amenities?.length > 0">
              <li v-for="(amenitie, index) in amenities" :key="index">
                <i class="fa-solid fa-check"></i> {{ amenitie.title }}
              </li>
            </ul>
            <p v-else>{{ $t("no_amenities") }}</p>
          </div>
          <!-- v-if="entertainments.length" -->
          <div class="card_list">
            <h5>
              <i class="fa-solid fa-utensils"></i> {{ $t("entertainments") }}
            </h5>
            <ul v-if="entertainments?.length > 0">
              <li v-for="(entertainment, index) in entertainments" :key="index">
                <i class="fa-solid fa-check"></i> {{ entertainment.title }}
              </li>
            </ul>
            <p v-else>{{ $t("no_entertainments") }}</p>
          </div>
          <!-- v-if="business.length" -->
          <div class="card_list">
            <h5>
              <i class="fa-solid fa-briefcase"></i>{{ $t("service_work") }}
            </h5>
            <ul v-if="business?.length > 0">
              <li v-for="(busines, index) in business" :key="index">
                <i class="fa-solid fa-check"></i> {{ busines.title }}
              </li>
            </ul>
            <p v-else>{{ $t("no_services") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "services-hotel",
  props: {
    amenities: Array,
    entertainments: Array,
    business: Array,
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_services.scss";
</style> -->
