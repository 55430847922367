<template>
  <div class="col-12" v-if="hotel.prices.length > 0 || priceFetched == false">
    <div class="hotel-card align-items-center">
      <div class="card-image">
        <img
          @error="setDefaultImage"
          :src="hotel.image"
          :class="{ 'error-image': isError }"
        />
      </div>
      <div class="card-text">
        <div class="title">
          <div class="details_hotel_data">
            <h3 class="card-title mt-0">
              {{ maxWordTruncate(hotel.title, 50) }}
            </h3>
            <!-- {{ hotel.starCount }} -->
            <!-- <rate :length="5" :value="parseInt(hotel.starCount)" :disabled="true" /> -->
            <rate :rates="hotel.starCount" />
            <!-- <div class="rate-number">
              ( {{ hotel.rating }} {{ $t("rate") }})
            </div> -->
          </div>
          <div class="price">
            <div class="loader_price" v-if="show_price && !priceFetched">
              <span class="loader"></span>
              <span class="best_price_text">{{
                $t("loading_best_price")
              }}</span>
            </div>
            <!-- v-if="show_price" -->
            <span v-if="show_price && priceFetched"
              >{{
                hotel.prices.length > 0
                  ? convertedPrice(hotel.prices[0].min_price)
                  : "--"
              }}
              {{ coinCode }} /
              {{ $t("for night") }}
            </span>
          </div>
        </div>
        <div class="option_rooms">
          <ul class="details">
            <li class="d-flex align-items-start justify-content-between">
              <div class="text-emphasis">
                <i class="fa-solid fa-location-dot"></i
                >{{ maxWordTruncate(hotel.address, 30) }}
              </div>
              <a
                class="show_map"
                :href="`https://www.google.com/maps?q=${lat},${long}&z=${zoom}`"
                target="_blank"
                >{{ $t("show_map") }}</a
              >
            </li>
            <li>
              <i class="fa-solid fa-shield"></i> {{ hotel.check_in }} /
              {{ hotel.check_out }}
            </li>
          </ul>
          <!-- {{ hotel.prices }} -->
          <button
            v-if="show_price && priceFetched"
            class="btn PrimaryButton w-25"
            type="button"
            @click="hotelDetails(hotel.prices)"
          >
            {{ $t("avabile_rooms") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
// import { json } from "body-parser";
import rate from "../rate.vue";

export default {
  name: "HotelCard",
  props: {
    hotel: Object,
    lat: Number,
    long: Number,
    session_id: String,
    show_price: Boolean,
    show_loader: Boolean,
    priceFetched: Boolean,
  },
  components: { rate },
  data() {
    return {
      zoom: 15,
      isError: false,
      // defaultImageUrl: require("@/assets/media/image/logo.png"),
      coinCode:
        localStorage.getItem("coinCode") == null
          ? "USD"
          : localStorage.getItem("coinCode"),
    };
  },
  methods: {
    hotelDetails(hotel_prices) {
      const hotelDataSearch = JSON.parse(
        localStorage.getItem("hotelDataSearch")
      );

      let hotelData = {
        result_index:
          this.hotel.metaData == null ? null : this.hotel.metaData.result_index,
        session_id: this.session_id == "" ? "" : this.session_id,
        nationality_code: hotelDataSearch.nationality_code,
        hotel_name: this.hotel.title,
        passenger_country_of_residence_code: hotelDataSearch.nationality_code,
        rooms_guests: hotelDataSearch.rooms_guests,
        check_in: hotelDataSearch.check_in,
        check_out: hotelDataSearch.check_out,
        no_of_rooms: hotelDataSearch.no_of_rooms,
        meta_data: {
          credential_id: this.hotel.credential_id,
          provider_id: this.hotel.provider_id,
        },
        hotel: this.hotel.providers[0].hotel_code,
      };
      localStorage.setItem("hotelData", JSON.stringify(hotelData));

      // List Rooms in hotel
      localStorage.setItem("hotel_prices", JSON.stringify(hotel_prices));

      this.$router.push(
        `/hotel/details/${this.hotel.providers[0].hotel_code}/${0}`
      );
    },

    setDefaultImage(event) {
      event.target.src = localStorage.getItem("DefaultImage");
      this.isError = true; // Set isError to true when an error occurs
    },
  },
  // mounted(){
  //   console.log(this.hotel.min_price)
  // }
};
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_HotelCard.scss";
</style> -->
