<template>
  <ul class="tabs_side">
    <template v-for="(service, index) in services" :key="index">
      <li v-if="service.service_name === 'HOTEL'">
        <router-link :to="{ name: 'hotelBooking' }">
          <div class="content">
            <span>
              <i class="fa-solid fa-calendar-days"></i>
            </span>
            <h6>{{ $t("hotel_reservation") }}</h6>
          </div>
          <div class="icon mt-0">
            <i class="fa-solid fa-chevron-left"></i>
          </div>
        </router-link>
      </li>

      <li v-if="service.service_name === 'FLIGHT'">
        <router-link :to="{ name: 'flightBooking' }">
          <div class="content">
            <span>
              <i class="fa-solid fa-plane"></i>
            </span>
            <h6>{{ $t("flight_reservation") }}</h6>
          </div>
          <div class="icon mt-0">
            <i class="fa-solid fa-chevron-left"></i>
          </div>
        </router-link>
      </li>

      <li v-if="service.service_name === 'PACKAGE'">
        <router-link :to="{ name: 'umrahBookingProfile' }">
          <div class="content">
            <span>
              <i class="fa-solid fa-kaaba"></i>
            </span>
            <h6>{{ $t("umrah_reservation") }}</h6>
          </div>
          <div class="icon mt-0">
            <i class="fa-solid fa-chevron-left"></i>
          </div>
        </router-link>
      </li>
    </template>

    <li>
      <router-link :to="{ name: 'personalData' }">
        <div class="content">
          <span> <i class="fa-solid fa-user-plus"></i> </span>
          <h6>{{ $t("personal_data") }}</h6>
        </div>
        <div class="icon mt-0">
          <i class="fa-solid fa-chevron-left"></i>
        </div>
      </router-link>
    </li>

    <li>
      <router-link :to="{ name: 'securitySetting' }">
        <div class="content">
          <span> <i class="fa-solid fa-lock"></i> </span>
          <h6>{{ $t("security") }}</h6>
        </div>
        <div class="icon mt-0">
          <i class="fa-solid fa-chevron-left"></i>
        </div>
      </router-link>
    </li>

    <!-- <li>
      <router-link :to="{ name: 'travelPerferance' }">
        <div class="content">
          <span> <i class="fa-solid fa-shield-heart"></i> </span>
          <h6>{{ $t("passenger_preferences") }}</h6>
        </div>
        <div class="icon">
          <i class="fa-solid fa-chevron-left"></i>
        </div>
      </router-link>
    </li> -->

    <li>
      <button class="btn w-100" @click="removeToken">
        <div class="content">
          <span> <i class="fa-solid fa-right-from-bracket"></i> </span>
          <h6>{{ $t("log_out") }}</h6>
        </div>
        <div class="icon mt-0">
          <i class="fa-solid fa-chevron-left"></i>
        </div>
      </button>
    </li>

    <!-- <li>
      <router-link to="#">
        <div class="content danger">
          <span> <i class="fa-solid fa-trash"></i> </span>
          <h6>{{ $t("delete_account") }}</h6>
        </div>
      </router-link>
    </li> -->
  </ul>
</template>

<script>
export default {
  name: "tabs-component",

  data() {
    return {
      services: JSON.parse(localStorage.getItem("services")),
      service_name: this.services && this.services?.map((service) => service),
    };
  },
  methods: {
    removeToken: function () {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      this.$router.go("/");
    },
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_tabs.scss";
</style> -->
