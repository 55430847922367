<template>
  <section class="terms">
    <div class="container">
      <h2 class="mx-0 px-0 mb-2">{{ $t("terms") }}</h2>
      <p v-html="terms.description"></p>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "terms-home",
  data() {
    return {
      terms: [],
    };
  },
  methods: {
    fetchTerms() {
      axios.get("/term").then(({ data }) => {
        this.terms = data.data;
      });
    },
  },
  mounted() {
    this.fetchTerms();
  },
};
</script>

<style></style>
